import React, { useState } from 'react';
import {
	Button,
	Grid,
	ThemeProvider,
	Typography,
	createTheme,
	Container,
	Card,
	CardActionArea,
	CardMedia,
	CardContent,
	Box,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	TextField,
	Chip,
	Divider,
	Alert,
} from '@mui/material';
import FooterTest from './FooterTest';
import MenuTest from './MenuTest';
import useIsMobile from './lib/Responsive';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogSnag from 'logsnag';
import { useNavigate } from 'react-router';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const sng = new LogSnag({
	token: 'ee66dc7f1d3ba4d39b98d7acac318360',
	project: 'hr',
});

const desktopTheme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
		secondary: {
			main: '#004D58',
		},
	},
	typography: {
		display: 'block',
		fontFamily: 'agrandir, Arial, Arial',
		fontSize: 16,
		color: '#004D58',
		body0: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 20,
			color: '#004D58',
		},
		body1: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 16,
			color: '#004D58',
		},
		body2: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 14,
			color: '#004D58',
		},
		h1: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 64,
			color: '#004D58',
			textAlign: 'left',
		},
		h2: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 48,
			color: '#004D58',
			textAlign: 'left',
		},
		h3: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 24,
			color: '#004D58',
			textAlign: 'left',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
				},
			},
		},
	},
});

const mobileTheme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
		secondary: {
			main: '#004D58',
		},
	},
	typography: {
		display: 'block',
		fontFamily: 'agrandir, Arial, Arial',
		fontSize: 14,
		color: '#004D58',
		body0: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 18,
			color: '#004D58',
		},
		body1: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 14,
			color: '#004D58',
		},
		body2: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 12,
			color: '#004D58',
		},
		h1: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 36,
			color: '#004D58',
			textAlign: 'center',
		},
		h2: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 28,
			color: '#004D58',
			textAlign: 'center',
		},
		h3: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 18,
			color: '#004D58',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
				},
			},
		},
	},
});

function RecruitmentLandingPage(props) {
	const [lang, setLang] = useState('cz');
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [city, setCity] = useState(null);
	const [validateEmailOrPhone, setValidateEmailOrPhone] = useState(null);
	const isMobile = useIsMobile();
	const navigate = useNavigate();

	const send = () => {
		const cityNames = [
			'Praha',
			'Brno',
			'Plzeň',
			'Liberec',
			'Hradec Králové',
			'Pardubice',
			'Olomouc',
			'České Budějovice',
			'Ostrava',
			'Karlovy Vary',
			'Zlín',
			'Jihlava',
			'Ústí nad Labem',
		];

		setValidateEmailOrPhone(phone != '' || email != '');

		if (phone == '' && email == '') return;

		const tel =
			'[instantní telefon](tel:' + phone.replace(/ /g, '%20') + ')';
		const mailto =
			'[instantní email]' +
			(
				'(mailto:kariera@youklid.cz?subject=Nový kandidát&body=Jméno: ' +
				name +
				'%0ATelefon: ' +
				phone +
				'%0AEmail: ' +
				email +
				'%0AMěsto: ' +
				cityNames[city] +
				'%0AJazyk: ' +
				lang +
				')'
			).replace(/ /g, '%20');

		async function sendApplication() {
			await sng.publish({
				project: 'hr',
				channel: 'applications',
				event: 'Nový kandidát',
				description:
					'Použít ' +
					tel +
					' nebo ' +
					mailto +
					'. Údaje: ' +
					name +
					'; ' +
					email +
					'; ' +
					phone +
					'; ' +
					cityNames[city] +
					'; ' +
					lang,
				icon: '💼',
				notify: true,
				tags: {
					city: cityNames[city],
				},
				parser: 'markdown',
			});

			navigate('/dekujeme-za-zajem');
		}
		sendApplication();
	};

	const copy = {
		lang: {
			cz: 'Jazyk',
			ua: 'Мова',
		},
		heading: {
			cz: 'Přijímáme nové hospodyně.',
			ua: 'Приймаємо нових господинь.',
		},
		subheading: {
			cz: (
				<span>
					Nabízíme práci v <strong>úklidu domácností</strong> s
					výplatou {!isMobile && <br />}{' '}
					<strong>170{'\u00A0'}Kč</strong> za{'\u00A0'}hodinu (navíc
					odměny níže){!isMobile && <br />} v Praze, Brně a okolí
					těchto měst.
				</span>
			),
			ua: (
				<span>
					Пропонуємо роботу з <strong>прибирання помешкань</strong> з
					оплатою {!isMobile && <br />}{' '}
					<strong>170{'\u00A0'}грн</strong> за годину (додаткові
					бонуси нижче){!isMobile && <br />} у Празі, Брно та навколо
					цих міст.
				</span>
			),
		},
		cta: {
			cz: 'Podat přihlášku',
			ua: 'Подати заяву',
		},
		about_heading: {
			cz: 'O nás',
			ua: 'Про нас',
		},
		about: {
			cz: (
				<span>
					Jsme úklidová společnost se zkušenostmi{' '}
					<strong>více než 13 let</strong> v oboru. Máme za sebou{' '}
					<strong>přes 30 000 uskutečněných úklidů</strong> a v našem
					týmu nyní pracuje <strong>více než 30 kolegů</strong>, kteří
					se starají o čisté domácnosti v Praze, Brně a jejich okolí.
					<br />
					<br />
					Naše společnost zaměstnává za legálních podmínek a nabízí
					stabilní zaměstnání v oboru.
				</span>
			),
			ua: (
				<span>
					Ми прибираємо <strong>вже більше 13 років</strong>. У нас за
					плечима <strong>більше 30 000 успішних прибирань</strong> та
					у нашій команді працює <strong>більше 30 колег</strong>, які
					дбають про чистоту в домі в Празі, Брно та їхньому
					навколишньому регіоні.
					<br />
					<br />
					Наша компанія пропонує легальне працевлаштування та
					стабільну роботу в цій галузі.
				</span>
			),
		},
		ethics_heading: {
			cz: 'Naše hodnoty',
			ua: 'Наші цінності',
		},
		ethics_intro: {
			cz: (
				<span>
					Pro naši společnost je důležitá kultura a slušné chování. To
					jsou zásady,{!isMobile && <br />} kterými se od ostatních
					lišíme a i přes růst na ně myslíme.
				</span>
			),
			ua: (
				<span>
					Для нашої компанії важлива культура та порядне ставлення. Це
					принципи,{!isMobile && <br />} які відрізняють нас від
					інших, і навіть під час зростання ми про них думаємо.
				</span>
			),
		},
		ethics: {
			cz: (
				<span>
					<ol>
						<li>
							{NumberCircle(1)} Zakládáme si na otevřenosti a
							pravdě.
						</li>
						<li>
							{NumberCircle(2)} Řídíme se skutečností, ne našimi
							pocity.
						</li>
						<li>
							{NumberCircle(3)} Jsme profesionální a spolehliví.
						</li>
						<li>
							{NumberCircle(4)} Kvalita je pro nás důležitější než
							růst.
						</li>
						<li>
							{NumberCircle(5)} Uznáváme férové a morální hodnoty.
						</li>
					</ol>
				</span>
			),
			ua: (
				<span>
					<ol>
						<li>
							{NumberCircle(1)} Ми надаємо перевагу відкритості та
							правді.
						</li>
						<li>
							{NumberCircle(2)} Ми керуємося фактами, а не
							почуттями.
						</li>
						<li>{NumberCircle(3)} Ми професійні та надійні.</li>
						<li>
							{NumberCircle(4)} Якість для нас важливіша за зріст.
						</li>
						<li>
							{NumberCircle(5)} Ми визнаємо справедливі та
							моральні цінності.
						</li>
					</ol>
				</span>
			),
		},
		news: {
			cz: 'Napsali o nás',
			ua: 'Про нас писали',
		},
		news_heading: {
			cz: (
				<>
					Maximální pohodlí a férově {!isMobile && <br />} zaplacené
					hospodyně
				</>
			),
			ua: (
				<>
					Максимальний комфорт і досить {!isMobile && <br />}{' '}
					оплачувані домробітниці
				</>
			),
		},
		news_text: {
			cz: (
				<span>
					Navíc, jak jsme zmínili – férový přístup vůči jejich
					hospodyním není jen tak. Kromě toho, že jsou vždy legálně
					zaměstnané, dostávají za odvedenou práci 70% z{'\u00A0'}
					tržby.
					<br />
					<br />
					Firma se stará o obě strany – klienty i hospodyně. Proto má
					na svých webových stránkách zveřejněný etický kodex. Tam se
					můžeme dočíst...
				</span>
			),
			ua: (
				<>
					Більше того, як ми зазначали – справедливе ставлення до
					своїх господинь – це не просто так. Крім того, що вони
					завжди легально працевлаштовані, вони отримують 70% виручки
					за свою роботу.
					<br />
					<br />
					Компанія піклується про обидві сторони – клієнтів і
					домробітниць. Тому на веб-сайті опубліковано кодекс етики.
					Там ми можемо прочитати...
				</>
			),
		},
		news_text_mobile: {
			cz: (
				<span>
					Navíc, jak jsme zmínili – férový přístup vůči jejich
					hospodyním není jen tak. Kromě toho, že jsou vždy legálně
					zaměstnané, dostávají za odvedenou práci 70% z{'\u00A0'}
					tržby...
				</span>
			),
			ua: (
				<>
					Більше того, як ми зазначали – справедливе ставлення до
					своїх господинь – це не просто так. Крім того, що вони
					завжди легально працевлаштовані, вони отримують 70%
					{'\u00A0'}виручки за виконану роботу...
				</>
			),
		},
		news_cta: {
			cz: 'Číst více...',
			ua: 'Детальніше...',
		},
		offer_heading: {
			cz: 'Co nabízíme a požadujeme',
			ua: 'Що ми пропонуємо і вимагаємо',
		},
		offer_left_description: {
			cz: '',
			ua: '',
		},
		offer_right_description: {
			cz: '',
			ua: '',
		},
		offer_left_list: {
			cz: (
				<span>
					<ol>
						<li>
							🧹 <b>Základní plat 170 Kč/h</b>.
						</li>
						<li>
							🧹 <b>Náborový příspěvek 3000 Kč</b> po zkušební
							době .
						</li>
						<li>
							🧹 <b>Bonusy až 3000 Kč</b> měsíčně dle hodnocení
							kvality.
						</li>
						<li>
							🧹 Můžete si zvolit{' '}
							<b>směny podle vlastní potřeby</b>.
						</li>
						<li>
							🧹 Možnost{' '}
							<b>zkráceného úvazku, brigády či dohody</b> o
							provedení práce.
						</li>
						<li>
							🧹 <b>Příspěvky na sport nebo stravenky</b>.
						</li>
						<li>
							🧹 Společné <b>firemní akce</b>.
						</li>
						<li>
							🧹 Práci začínáte <b>cestou z vlastního domova</b>.
						</li>
					</ol>
				</span>
			),
			ua: (
				<ol>
					<li>
						🧹 <b>Основна зарплата 170 крон/год</b>.
					</li>
					<li>
						🧹 <b>3 000 крон на роботу</b> після випробувального
						терміну.
					</li>
					<li>
						🧹 <b>Бонус до 3000 чеських крон</b> на місяць
						відповідно до оцінки якості.
					</li>
					<li>
						🧹 Ви можете вибрати{' '}
						<b>зміни відповідно до власних потреб</b>.
					</li>
					<li>
						🧹 Можливість{' '}
						<b>
							сумісництва, неповного робочого дня або домовленості
						</b>{' '}
						про виконання роботи.
					</li>
					<li>
						🧹{' '}
						<b>
							Внески на заняття спортом або ваучери на харчування
						</b>
						.
					</li>
					<li>
						🧹 Поширені <b>корпоративи</b>.
					</li>
					<li>
						🧹 Ви починаєте працювати, <b>подорожуючи з дому</b>.
					</li>
				</ol>
			),
		},
		offer_right_list: {
			cz: (
				<span>
					<ol>
						<li>
							🧹 Nevadí vám <b>cestování po městě</b> a jeho okolí
							(dle dohody).
						</li>
						<li>
							🧹 Vlastníte{' '}
							<b>chytrý telefon s datovým balíčkem</b>.
						</li>
						<li>
							🧹 Máte <b>čistý trestní rejstřík</b> a{' '}
							<b>pracovní povolení</b> v České republice.
						</li>
						<li>
							🧹 Záleží vám na <b>morálních hodnotách</b> a máte
							dobré vystupování.
						</li>
						<li>
							🧹 Je pro vás <b>důležitá kvalita, spolehlivost</b>{' '}
							a <b>dobře vykonaná práce</b>.
						</li>
						<li>
							🧹 <b>Základní hovorová znalost</b> českého jazyka.
						</li>
					</ol>
				</span>
			),
			ua: (
				<ol>
					<li>
						🧹 Ви не проти <b>подорожувати містом</b> та його
						околицями (за домовленістю).
					</li>
					<li>
						🧹 У вас є <b>смартфон із пакетом даних</b>.
					</li>
					<li>
						🧹 Ви маєте <b>чисту судимість</b> і{' '}
						<b>дозвіл на роботу</b> в Чехії.
					</li>
					<li>
						🧹 Ви дбаєте про <b>моральні цінності</b> і маєте хороші
						манери.
					</li>
					<li>
						🧹 Для вас важливі <b>якість, надійність</b> і{' '}
						<b>добре виконана робота</b>.
					</li>
					<li>
						🧹 <b>Базове розмовне знання</b> чеської мови.
					</li>
				</ol>
			),
		},
		faq_heading: {
			cz: 'Často kladené otázky',
			ua: 'поширені запитання',
		},
		faq: [
			{
				question: {
					cz: 'Jak rychle k vám mohu nastoupit?',
					ua: 'Як швидко я можу приєднатися до вас?',
				},
				answer: {
					cz: 'Každý týden přijímáme do péče nové domácnosti. Nástup je možný ihned po dokončení školení (přibližně 3 dny od kontaktu po podpis).',
					ua: 'Щотижня приймаємо нових домогосподарств. Приєднання можливе відразу після проходження навчання (приблизно 3 дні від контакту до підпису).',
				},
			},
			{
				question: {
					cz: 'Kolik si vydělám čisté mzdy (přijde mi na účet)?',
					ua: 'Скільки я отримаю чистої зарплати (надходить на мій рахунок)?',
				},
				answer: {
					cz: 'Čistá mzda se vypočítává podle slev na dani (vyživované děti, další pracovní úvazky a velikost úvazku). Obvykle se pohybuje mezi 150-170 Kč/h.',
					ua: 'Заробітна плата нетто розраховується відповідно до податкових відрахувань (діти на утриманні, інший робочий час і розмір часу). Зазвичай вона коливається в межах 150-170 крон/год.',
				},
			},
			{
				question: {
					cz: 'Jaké druhy a formy spolupráce nabízíte?',
					ua: 'Які види та форми співпраці Ви пропонуєте?',
				},
				answer: {
					cz: 'Nabízíme formu DPP (dohoda o provedení práce), DPČ (dohoda o pracovní činnosti) a HPP (hlavní pracovní poměr). Naše druhy spolupráce jsou tedy vhodné jak pro brigády, poloviční úvazek i hlavní práci.',
					ua: 'Ми пропонуємо форму DPP (трудовий договір), DPČ (трудовий договір) і HPP (основні трудові відносини). Тому наші види співпраці підходять для сумісництва, неповного робочого дня та основної роботи.',
				},
			},
			{
				question: {
					cz: 'Je možné dojíždět pomocí hromadné dopravy?',
					ua: 'У мене немає машини. Чи можна доїхати громадським транспортом?',
				},
				answer: {
					cz: 'Ano, všechny oblasti kde uklízíme jsou v dobré dostupnosti veřejné dopravy.',
					ua: 'Так, до всіх територій, де ми прибираємо, можна легко дістатися громадським транспортом.',
				},
			},
			{
				question: {
					cz: 'Uklízíte i během víkendů a státních svátků?',
					ua: 'Ви також прибираєте у вихідні та святкові дні?',
				},
				answer: {
					cz: 'Během víkendů běžně neuklízíme, státní svátky dle společné dohody a nastavení směn.',
					ua: 'Зазвичай ми не прибираємо у вихідні та святкові дні за взаємною домовленістю та змінами.',
				},
			},
			{
				question: {
					cz: 'Musím vozit ke klientovi čistící prostředky?',
					ua: 'Чи потрібно приносити клієнту чистячі засоби?',
				},
				answer: {
					cz: 'Běžně není potřeba ke klientům úklidové prostředky vozit. Výjimkou je část prvních úklidů, kde se klienti rozhodli zakoupit náš balíček prostředků (přibližně velikosti krabice od bot). K těmto klientům je možné balíčky které vám přivezeme za příplatek zavážet.',
					ua: 'Зазвичай немає потреби приносити клієнтам чистячі засоби. Винятком є ​​частина перших прибирань, де клієнти вирішили придбати наш пакет ресурсів (розміром приблизно з взуттєву коробку). Для цих клієнтів можлива відправка посилок, які ми вам привозимо, за додаткову плату.',
				},
			},
			{
				question: {
					cz: 'Jak se dozvím o nových úklidech?',
					ua: 'Як дізнатися про нові чистки?',
				},
				answer: {
					cz: 'Po přijetí do naší společnosti dostanete přístup do aplikace, ve které vidíte plánované úklidy. V aplikaci si můžete také kdykoliv zkontrolovat výplatu, nastavit směny nebo potvrzovat nově přidané úklidy.',
					ua: 'Після прийому в нашу компанію ви отримаєте доступ до додатку, в якому ви зможете побачити заплановані прибирання. У додатку також можна в будь-який час перевірити оплату, встановити зміни або підтвердити нещодавно додані прибирання.',
				},
			},
		],
		process_heading: {
			cz: 'Jaké jsou další kroky?',
			ua: 'Які наступні кроки?',
		},
		process: {
			cz: (
				<span>
					<ol>
						<li>
							{NumberCircle(1)} Zanechte nám na sebe ve formuláři
							kontakt, na který se můžeme ozvat.
						</li>
						<li>
							{NumberCircle(2)} Do 24 hodin Vás kontaktujeme s
							návrhem termínu osobního setkání.
						</li>
						<li>
							{NumberCircle(3)} Po setkání zasíláme školení s
							testem, po kterém Vás pozveme na zkoušku.
						</li>
						<li>
							{NumberCircle(4)} Po dokončení praktické zkoušky
							společně podepíšeme smlouvu.
						</li>
						<li>
							{NumberCircle(5)}{' '}
							<strong>
								Gratulujeme! Získáváte do péče první domovy
								našich zákazníků. 🎉
							</strong>
						</li>
					</ol>
				</span>
			),
			ua: (
				<span>
					<ol>
						<li>
							{NumberCircle(1)} Залиште нам свою контактну
							інформацію у формі, щоб ми могли зв’язатися з вами.
						</li>
						<li>
							{NumberCircle(2)} Ми зв'яжемося з вами протягом 24
							годин з пропозицією щодо дати особистої зустрічі.
						</li>
						<li>
							{NumberCircle(3)} Після зустрічі відправляємо
							тренінг з тестом, після чого запрошуємо на іспит.
						</li>
						<li>
							{NumberCircle(4)} Після складання практичного іспиту
							ми разом підпишемо договір.
						</li>
						<li>
							{NumberCircle(5)}{' '}
							<strong>
								Щиро вітаю! Ви дбаєте про перші будинки наших
								клієнтів. 🎉
							</strong>
						</li>
					</ol>
				</span>
			),
		},
		process_mobile: {
			cz: (
				<span>
					<ol>
						<li>
							{NumberCircleMobile(1)} Zanechte nám na sebe ve
							formuláři kontakt, na který se můžeme ozvat.
						</li>
						<li>
							{NumberCircleMobile(2)} Do 24 hodin Vás kontaktujeme
							s návrhem termínu osobního setkání.
						</li>
						<li>
							{NumberCircleMobile(3)} Po setkání zasíláme školení
							s testem, po kterém Vás pozveme na zkoušku.
						</li>
						<li>
							{NumberCircleMobile(4)} Po dokončení praktické
							zkoušky společně podepíšeme smlouvu.
						</li>
						<li>
							{NumberCircleMobile(5)}{' '}
							<strong>
								Gratulujeme! Získáváte do péče první domovy
								našich zákazníků. 🎉
							</strong>
						</li>
					</ol>
				</span>
			),
			ua: (
				<span>
					<ol>
						<li>
							{NumberCircleMobile(1)} Залиште нам свою контактну
							інформацію у формі, щоб ми могли зв’язатися з вами.
						</li>
						<li>
							{NumberCircleMobile(2)} Ми зв'яжемося з вами
							протягом 24 годин з пропозицією щодо дати особистої
							зустрічі.
						</li>
						<li>
							{NumberCircleMobile(3)} Після зустрічі відправляємо
							тренінг з тестом, після чого запрошуємо на іспит.
						</li>
						<li>
							{NumberCircleMobile(4)} Після складання практичного
							іспиту ми разом підпишемо договір.
						</li>
						<li>
							{NumberCircleMobile(5)}{' '}
							<strong>
								Щиро вітаю! Ви дбаєте про перші будинки наших
								клієнтів. 🎉
							</strong>
						</li>
					</ol>
				</span>
			),
		},
		process_summary: {
			cz: (
				<>
					...to vše zvládneme s uchazeči{isMobile && <br />} obvykle
					do 3 pracovních dnů!
				</>
			),
			ua: 'Ми можемо впоратися з усім цим із заявниками зазвичай протягом 3 робочих днів.',
		},
		form_heading: {
			cz: 'Přihlaste se do Youklidu',
			ua: 'Зареєструйтеся в Youklidu',
		},
		form_description: {
			cz: (
				<>
					Jste připraveni na novou výzvu?
					<br /> <b>Zanechte nám na sebe kontakt</b>
					<br /> a začněte svou kariéru s námi!
				</>
			),
			ua: (
				<>
					Ви готові до нового виклику?
					<br /> <b>Залиште нам контакт</b>
					<br /> і почніть свою кар'єру з нами!
				</>
			),
		},
		form_choose_location: {
			cz: 'Vyberte si kde chcete pracovat',
			ua: 'Виберіть, де ви хочете працювати',
		},
		send_form: {
			cz: 'Podat přihlášku',
			ua: 'Подайте заявку',
		},
		form_name: {
			cz: 'Jméno a příjmení',
			ua: "Прізвище та ім'я",
		},
		form_phone: {
			cz: 'Telefon',
			ua: 'Телефон',
		},
		form_email: {
			cz: 'Email',
			ua: 'Електронна пошта',
		},
		form_error: {
			cz: 'Vyplňte prosím tento údaj.',
			ua: 'Будь ласка, заповніть цю інформацію.',
		},
		contact_error: {
			cz: 'Prosím vyplňte email nebo telefon.',
			ua: 'Будь ласка, введіть свою електронну адресу або номер телефону.',
		},
	};

	const locations = [
		{
			name: {
				cz: 'Praha',
				ua: 'Прага',
			},
			url: '/img/thumbnails/praha.png',
		},
		{
			name: {
				cz: 'Brno',
				ua: 'Брно',
			},
			url: '/img/thumbnails/brno.png',
		},
		{
			name: {
				cz: 'Plzeň',
				ua: 'Пльзень',
			},
			url: '/img/thumbnails/plzen.png',
		},
		{
			name: {
				cz: 'Liberec',
				ua: 'Ліберець',
			},
			url: '/img/thumbnails/liberec.png',
		},
		// {
		// 	name: {
		// 		cz: 'Hradec Králové',
		// 		ua: 'Градець-Кралове',
		// 	},
		// 	url: '/img/thumbnails/hradec.png',
		// },
		// {
		// 	name: {
		// 		cz: 'Pardubice',
		// 		ua: 'Пардубіце',
		// 	},
		// 	url: '/img/thumbnails/pardubice.png',
		// },
		// {
		// 	name: {
		// 		cz: 'Olomouc',
		// 		ua: 'Оломоуць',
		// 	},
		// 	url: '/img/thumbnails/olomouc.png',
		// },
		// {
		// 	name: {
		// 		cz: 'České Budějovice',
		// 		ua: 'Čеськi Будєйовiце',
		// 	},
		// 	url: '/img/thumbnails/budejovice.png',
		// },
		// {
		// 	name: {
		// 		cz: 'Ostrava',
		// 		ua: 'Острава',
		// 	},
		// 	url: '/img/thumbnails/ostrava.png',
		// },
		// {
		// 	name: {
		// 		cz: 'Karlovy Vary',
		// 		ua: 'Карлові Вари',
		// 	},
		// 	url: '/img/thumbnails/vary.png',
		// },
		// {
		// 	name: {
		// 		cz: 'Zlín',
		// 		ua: 'Злін',
		// 	},
		// 	url: '/img/thumbnails/zlin.png',
		// },
		// {
		// 	name: {
		// 		cz: 'Jihlava',
		// 		ua: 'Їглава',
		// 	},
		// 	url: '/img/thumbnails/jihlava.png',
		// },
		// {
		// 	name: {
		// 		cz: 'Ústí nad Labem',
		// 		ua: 'Úсті-над-Лабем',
		// 	},
		// 	url: '/img/thumbnails/usti.png',
		// },
	];

	const openTab = url => {
		window.open(url, '_blank');
	};

	return (
		<>
			<MenuTest />
			<ThemeProvider theme={isMobile ? mobileTheme : desktopTheme}>
				<div style={{ height: isMobile ? 80 : 115 }} />
				<Container maxWidth={'md'}>
					<Grid
						container
						justifyContent={'flex-end'}
						alignItems={'center'}
						spacing={2}
					>
						<Grid item>
							<Typography variant="h3">
								{copy.lang[lang]}:
							</Typography>
						</Grid>
						<Grid item>
							<img
								src="/img/cz.svg"
								alt="Česky"
								style={{ width: 28 }}
								onClick={() => setLang('cz')}
							/>
						</Grid>
						<Grid item>
							<img
								src="/img/ua.svg"
								alt="українська"
								style={{ width: 28 }}
								onClick={() => setLang('ua')}
							/>
						</Grid>
					</Grid>
					<div style={{ height: 40 }} />
					<Grid
						container
						justifyContent={'space-between'}
						alignItems={'flex-start'}
					>
						<Grid item xs={12} md={8}>
							<Typography variant="h1">
								{copy.heading[lang]}
							</Typography>
							<div style={{ height: 10 }} />
							<Typography
								variant="body1"
								style={{
									textAlign: isMobile ? 'center' : 'left',
								}}
							>
								{copy.subheading[lang]}
							</Typography>
							<div style={{ height: 20 }} />
							{isMobile ? (
								<Grid
									container
									justifyContent={'center'}
									style={{ marginBottom: 40 }}
								>
									<AnchorLink href="#formular">
										<Button variant="contained">
											<span
												style={{
													position: 'relative',
													top: 1,
												}}
											>
												{copy.cta[lang]}
											</span>
										</Button>
									</AnchorLink>
								</Grid>
							) : (
								<AnchorLink href="#formular">
									<Button variant="contained">
										<span
											style={{
												position: 'relative',
												top: 1,
											}}
										>
											{copy.cta[lang]}
										</span>
									</Button>
								</AnchorLink>
							)}
						</Grid>
						{!isMobile && (
							<Grid item xs={12} md={4}>
								<img
									src="/img/frontpage_images/hr_page_hero_small.png"
									alt=""
								/>
							</Grid>
						)}
					</Grid>
				</Container>
				<div style={{ width: '100%', background: '#ecfaf7' }}>
					<Container maxWidth={'md'} style={{ padding: '40px 0' }}>
						<Grid
							container
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							{!isMobile && (
								<Grid item xs={12} md={6}>
									<img
										src="/img/dog.svg"
										alt=""
										style={{ transform: 'scaleX(-1)' }}
									/>
								</Grid>
							)}
							<Grid item xs={12} md={6}>
								<Typography variant="h2" gutterBottom>
									{copy.about_heading[lang]}
								</Typography>
								<Typography
									variant="body1"
									style={{
										textAlign: isMobile ? 'center' : 'left',
										padding: isMobile ? '16px' : 'initial',
									}}
								>
									{copy.about[lang]}
								</Typography>
								<div style={{ height: 20 }} />
								{isMobile ? (
									<Grid container justifyContent={'center'}>
										<AnchorLink href="#formular">
											<Button variant="contained">
												<span
													style={{
														position: 'relative',
														top: 1,
													}}
												>
													{copy.cta[lang]}
												</span>
											</Button>
										</AnchorLink>
									</Grid>
								) : (
									<AnchorLink href="#formular">
										<Button variant="contained">
											<span
												style={{
													position: 'relative',
													top: 1,
												}}
											>
												{copy.cta[lang]}
											</span>
										</Button>
									</AnchorLink>
								)}
							</Grid>
						</Grid>
					</Container>
				</div>
				<div style={{ width: '100%', background: '#dcf6f0' }}>
					<Container maxWidth={'md'} style={{ padding: '80px 0' }}>
						<Grid
							container
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							<Grid item xs={12} md={12}>
								<Grid
									container
									justifyContent={'center'}
									alignItems={'center'}
									flexDirection={'column'}
								>
									<Typography variant="h2" gutterBottom>
										{copy.ethics_heading[lang]}
									</Typography>
									<div style={{ height: 20 }} />
									<Typography
										variant="body1"
										style={{
											textAlign: 'center',
											padding: isMobile
												? '16px'
												: 'initial',
										}}
									>
										{copy.ethics_intro[lang]}
									</Typography>
									<div style={{ height: 40 }} />
									<Typography
										variant="body1"
										style={{
											fontSize: isMobile
												? 'initial'
												: '1.2em',
											padding: isMobile
												? '16px'
												: 'initial',
										}}
									>
										{copy.ethics[lang]}
									</Typography>
									<div style={{ height: 40 }} />
									<AnchorLink href="#formular">
										<Button variant="contained">
											<span
												style={{
													position: 'relative',
													top: 1,
												}}
											>
												{copy.cta[lang]}
											</span>
										</Button>
									</AnchorLink>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</div>
				<div style={{ width: '100%', background: '#cdf2e9' }}>
					<Container maxWidth={'md'} style={{ padding: '80px 0' }}>
						<Typography
							variant="h2"
							style={{ textAlign: 'center', marginBottom: 40 }}
						>
							{copy.news[lang]}
						</Typography>
						{isMobile ? (
							<Grid container justifyContent={'center'}>
								<Card
									style={{ width: '300px', height: '100%' }}
								>
									<CardActionArea
										onClick={() =>
											openTab(
												'https://www.idnes.cz/jenprozeny/volny-cas/maximalni-pohodli-a-ferove-zaplacene-hospodyne.A230111_153738_jpz-volny-cas_shlo'
											)
										}
										style={{
											height: '100%',
											alignItems: 'flex-start',
											flexDirection: 'column',
										}}
									>
										<CardMedia
											sx={{
												width: '300px',
												height: '200px',
											}}
											image={'/img/thumbnails/idnes.png'}
											title={
												'Maximální pohodlí a férově zaplacené hospodyně'
											}
											style={{
												backgroundPosition:
													'top center',
												backgroundSize: 'cover',
											}}
										/>
										<CardContent>
											<Grid
												container
												justifyContent={'space-between'}
											>
												<Typography
													gutterBottom
													variant="body1"
													style={{ fontSize: 12 }}
												>
													iDnes.cz
												</Typography>
												<Typography
													gutterBottom
													variant="body1"
													style={{ fontSize: 12 }}
												>
													13.1. 2023
												</Typography>
											</Grid>
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												style={{ fontSize: 20 }}
											>
												{copy.news_heading[lang]}
											</Typography>
											<Typography
												gutterBottom
												variant="body1"
												component="div"
											>
												{copy.news_text_mobile[lang]}
											</Typography>
											<Typography
												gutterBottom
												variant="body1"
												component="div"
												style={{ textAlign: 'right' }}
											>
												{copy.news_cta[lang]}
											</Typography>
										</CardContent>
									</CardActionArea>
								</Card>
							</Grid>
						) : (
							<Card style={{ borderRadius: 16 }}>
								<CardActionArea
									style={{ height: '100%', display: 'flex' }}
									onClick={() =>
										openTab(
											'https://www.idnes.cz/jenprozeny/volny-cas/maximalni-pohodli-a-ferove-zaplacene-hospodyne.A230111_153738_jpz-volny-cas_shlo'
										)
									}
								>
									<CardContent
										style={{ flex: 2, padding: 32 }}
									>
										<Typography
											gutterBottom
											variant="h3"
											component="div"
										>
											{copy.news_heading[lang]}
										</Typography>
										<Grid
											container
											justifyContent={'space-between'}
											style={{ marginTop: 'auto' }}
										>
											<Typography
												gutterBottom
												variant="body1"
												style={{ fontSize: 12 }}
											>
												13.1. 2023, iDnes.cz
											</Typography>
										</Grid>
										<Typography
											gutterBottom
											variant="body1"
											component="div"
										>
											{copy.news_text[lang]}
										</Typography>
										<Typography
											gutterBottom
											variant="body1"
											component="div"
											style={{ textAlign: 'right' }}
										>
											{copy.news_cta[lang]}
										</Typography>
									</CardContent>
									<CardMedia
										style={{
											width: 600,
											height: 400,
											backgroundPosition: 'top',
											flex: 2,
										}}
										image={'/img/thumbnails/idnes.png'}
										title={
											'Maximální pohodlí a férově zaplacené hospodyně'
										}
									/>
								</CardActionArea>
							</Card>
						)}
					</Container>
				</div>
				<div style={{ width: '100%', background: '#dcf6f0' }}>
					<Container maxWidth={'md'} style={{ padding: '80px 0' }}>
						<Typography
							variant="h2"
							style={{
								textAlign: 'center',
								marginBottom: isMobile ? 0 : 40,
							}}
						>
							{copy.offer_heading[lang]}
						</Typography>
						<Grid container>
							<Grid item xs={12} md={6}>
								<Typography
									variant="body1"
									style={{
										textAlign: 'left',
										marginBottom: 20,
									}}
								>
									{copy.offer_left_description[lang]}
								</Typography>
								<Typography
									variant="body1"
									style={{
										padding: isMobile ? '16px' : 'initial',
									}}
								>
									{copy.offer_left_list[lang]}
								</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<Typography
									variant="body1"
									style={{
										textAlign: 'left',
										marginBottom: 20,
									}}
								>
									{copy.offer_right_description[lang]}
								</Typography>
								<Typography
									variant="body1"
									style={{
										padding: isMobile ? '16px' : 'initial',
									}}
								>
									{copy.offer_right_list[lang]}
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								md={12}
								style={{ marginTop: 30 }}
							>
								<Grid container justifyContent={'center'}>
									<AnchorLink href="#formular">
										<Button variant="contained">
											<span
												style={{
													position: 'relative',
													top: 1,
												}}
											>
												{copy.cta[lang]}
											</span>
										</Button>
									</AnchorLink>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</div>
				<div style={{ width: '100%', background: '#cdf2e9' }}>
					<Container maxWidth={'md'} style={{ padding: '80px 0' }}>
						<Typography
							variant="h2"
							style={{ textAlign: 'center', marginBottom: 40 }}
						>
							{copy.faq_heading[lang]}
						</Typography>
						<div>
							{copy.faq.map(item => (
								<Accordion
									disableGutters
									style={{ borderRadius: 0 }}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
									>
										<Typography variant="body1">
											<strong>
												{item.question[lang]}
											</strong>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography variant="body1">
											{item.answer[lang]}
										</Typography>
									</AccordionDetails>
								</Accordion>
							))}
						</div>
					</Container>
				</div>
				<div style={{ width: '100%', background: '#a1e7d7' }}>
					<Container maxWidth={'md'} style={{ padding: '80px 0' }}>
						<Grid
							container
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							<Grid item xs={12} md={12}>
								<Grid
									container
									justifyContent={'center'}
									alignItems={'center'}
									flexDirection={'column'}
								>
									<Typography variant="h2" gutterBottom>
										{copy.process_heading[lang]}
									</Typography>
									<div style={{ height: 20 }} />
									<Typography
										variant="body1"
										style={{
											fontSize: isMobile
												? 'initial'
												: '1.2em',
											padding: '16px',
											textAlign: isMobile
												? 'center'
												: 'initial',
										}}
									>
										{isMobile
											? copy.process_mobile[lang]
											: copy.process[lang]}
									</Typography>
									<div style={{ height: 40 }} />
									<Typography
										variant="body1"
										style={{ padding: '16px' }}
									>
										{copy.process_summary[lang]}
									</Typography>
									<div style={{ height: 40 }} />
									<AnchorLink href="#formular">
										<Button variant="contained">
											<span
												style={{
													position: 'relative',
													top: 1,
												}}
											>
												{copy.cta[lang]}
											</span>
										</Button>
									</AnchorLink>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</div>
				<div style={{ width: '100%', background: '#004d58' }}>
					<div
						id="formular"
						style={{ position: 'relative', top: -60 }}
					/>
					<Container maxWidth={'md'} style={{ padding: '80px 0' }}>
						<div
							style={{
								background: '#FBFDFC',
								borderRadius: 30,
								padding: isMobile ? 40 : 60,
							}}
						>
							<Typography
								variant="h1"
								style={{
									fontSize: isMobile ? 28 : 48,
									color: '#004d58',
									fontFamily: 'agrandir, Arial',
									textAlign: 'center',
									marginBottom: 20,
								}}
							>
								{copy.form_heading[lang]}
							</Typography>
							<Typography
								style={{
									fontFamily: 'agrandir, Arial',
									fontSize: isMobile ? 16 : 20,
									textAlign: 'center',
									marginBottom: 40,
									color: '#004D58',
								}}
							>
								{copy.form_description[lang]}
							</Typography>
							<Grid
								container
								justifyContent={'center'}
								alignItems={'center'}
								flexDirection={'column'}
								columnSpacing={6}
								rowSpacing={3}
							>
								<Grid
									item
									xs={12}
									md={8}
									style={{ width: '100%' }}
								>
									{validateEmailOrPhone === false && (
										<Alert
											severity="warning"
											style={{
												width: '100%',
												borderRadius: 100,
											}}
										>
											{copy.contact_error[lang]}
										</Alert>
									)}
								</Grid>
								<Grid
									item
									xs={12}
									md={8}
									style={{ width: '100%' }}
								>
									<TextField
										color="secondary"
										style={{
											backgroundColor: '#FBFDFC',
											borderRadius: '6px',
											width: '100%',
										}}
										label={copy.form_name[lang]}
										//error={name === ''}
										//helperText={name === '' ? copy.form_error[lang] : ''}
										value={name}
										onChange={e => {
											setName(e.target.value);
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={8}
									style={{ width: '100%' }}
								>
									<TextField
										color="secondary"
										style={{
											backgroundColor: '#FBFDFC',
											borderRadius: '6px',
											width: '100%',
										}}
										label={copy.form_phone[lang]}
										//error={phone === ''}
										//helperText={phone === '' ? copy.form_error[lang] : ''}
										value={phone}
										onChange={e => {
											setPhone(e.target.value);
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={8}
									style={{ width: '100%' }}
								>
									<TextField
										color="secondary"
										style={{
											backgroundColor: '#FBFDFC',
											borderRadius: '6px',
											width: '100%',
										}}
										label={copy.form_email[lang]}
										//error={email === ''}
										//helperText={email === '' ? copy.form_error[lang] : ''}
										value={email}
										onChange={e => {
											setEmail(e.target.value);
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									style={{ margin: '10px 0 30px 0' }}
								>
									<Typography
										style={{
											fontFamily: 'agrandir, Arial',
											fontSize: isMobile ? 16 : 20,
											textAlign: 'center',
											marginBottom: 24,
											color: '#004D58',
										}}
									>
										{copy.form_choose_location[lang]}
									</Typography>
									<style>
										{`
											.city_button:hover{
												filter: grayscale(0%) !important;
											}

											.city_button_selected{
												border: 5px #004d58 solid !important;
												filter: grayscale(0%) !important;
											}
										`}
									</style>
									<Grid
										container
										justifyContent={'center'}
										spacing={isMobile ? 0 : 2}
									>
										{locations.map((location, index) => (
											<Grid item>
												<div
													className={
														city === index
															? 'city_button city_button_selected'
															: 'city_button'
													}
													onClick={() =>
														setCity(index)
													}
													style={{
														width: isMobile
															? 140
															: 150,
														height: isMobile
															? 120
															: 130,
														borderRadius: isMobile
															? 16
															: 22,
														backgroundImage:
															'url(' +
															location.url +
															')',
														backgroundColor:
															'#ffffff',
														backgroundPosition:
															'center center',
														backgroundRepeat:
															'no-repeat',
														backgroundSize: 'cover',
														filter: 'grayscale(100%)',
														transition: 'ease 0.1s',
														border: '5px #fff solid',
														boxSizing:
															'content-box',
														position: 'relative',
													}}
												>
													<div
														style={{
															position:
																'absolute',
															inset: 'auto 0 0 0',
															textAlign: 'center',
															background:
																'rgba(255,255,255,0.8)',
															fontFamily:
																'agrandir, Arial',
															fontSize: isMobile
																? 16
																: 18,
															fontWeight: 600,
															color: '#004d58',
															padding: '8px 0',
															borderRadius:
																isMobile
																	? '0 0 10px 10px'
																	: '0 0 16px 16px',
														}}
													>
														{location.name[lang]}
													</div>
												</div>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
							<Grid container justifyContent={'center'}>
								<Button
									variant="filled"
									style={{
										color: '#FBFDFC',
										backgroundColor: '#8C5CBC',
										height: '56px',
										fontSize: 20,
										fontFamily: 'agrandir, Arial',
										margin: '10px',
										borderRadius: 500,

										borderColor: '#8C5CBC',
										textTransform: 'initial',
										padding: '10px 34px',
										boxShadow: 'none',
									}}
									onClick={send}
								>
									<span
										style={{
											position: 'relative',
											top: '2px',
										}}
									>
										{copy.send_form[lang]}
									</span>
								</Button>
							</Grid>
						</div>
					</Container>
				</div>
			</ThemeProvider>
			<FooterTest background={'#004d58'} />
		</>
	);
}

function NumberCircle(number) {
	return (
		<div
			style={{
				background: '#004d58',
				color: '#fff',
				fontSize: 20,
				fontFamily: 'agrandir, Arial',
				borderRadius: 50,
				padding: 4,
				width: 40,
				textAlign: 'center',
				display: 'inline-block',
				marginBottom: 8,
				marginRight: 16,
			}}
		>
			<span style={{ position: 'relative', top: 2 }}>{number}</span>
		</div>
	);
}

function NumberCircleMobile(number) {
	return (
		<Grid container justifyContent={'center'}>
			<div
				style={{
					background: '#004d58',
					color: '#fff',
					fontSize: 20,
					fontFamily: 'agrandir, Arial',
					borderRadius: 50,
					padding: 4,
					width: 40,
					textAlign: 'center',
					//display: 'inline-block',
					marginBottom: 8,
					marginRight: 16,
				}}
			>
				<span style={{ position: 'relative', top: 2 }}>{number}</span>
			</div>
		</Grid>
	);
}

export default RecruitmentLandingPage;
