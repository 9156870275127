import {
  Box,
  Button,
  Typography,
  Container,
  TextField,
  Alert,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ContentWrapper from "./Wrapper/ContentWrapper";
import BackButton from "./BackButton";
import LayoutCard from "./Components/LayoutCard";

function AccountSettings(props) {
  const [state, setState] = useState({
    user: {
      user_email: "",
      user_firstname: "",
      user_lastname: "",
      user_phone: "",
      user_email_initial: "",
      user_firstname_initial: "",
      user_lastname_initial: "",
      user_phone_initial: "",
      user_invoice_name_initial: "",
      user_invoice_name: "",
      user_street_and_number: "",
      user_zip: "",
      user_city: "",
      user_ic: "",
      user_dic: "",
    },
    user_email_changed: false,
    user_firstname_changed: false,
    user_lastname_changed: false,
    user_phone_changed: false,
    user_invoice_name: false,
    user_street_and_number_changed: false,
    user_zip_changed: false,
    user_city_changed: false,
    user_ic_changed: false,
    user_dic_changed: false,
    password: "",
    password_confirmation: "",
    alertClosed: false,
    error_msg: null,
    severity: "error",
  });

  let uid, token;
  let dataOK = true;
  if (localStorage.getItem("uid")) {
    uid = localStorage.getItem("uid");
  } else {
    // goto login
    dataOK = false;
  }
  if (localStorage.getItem("token")) {
    token = localStorage.getItem("token");
  } else {
    // goto login
    dataOK = false;
  }

  useEffect(() => {
    if (!dataOK) {
      navigate("/login");
    } else {
      fetch("https://chciuklizeno.cz/api/users/" + uid + "?token=" + token)
        .then((response) => {
          if (response.status != 200) {
            navigate("/login");
          }
          return response;
        })
        .then((response) => response.json())
        .then((data) => {
          setState((state) => {
            return {
              ...state,
              user: {
                ...data.user,
                user_email_initial: data.user.user_email,
                user_firstname_initial: data.user.user_firstname,
                user_lastname_initial: data.user.user_lastname,
                user_phone_initial: data.user.user_phone,
                user_invoice_name_initial: data.user.invoice_info_name,
                user_street_and_number_initial: data.user.invoice_info_street,
                user_zip_initial: data.user.invoice_info_postal_code,
                user_city_initial: data.user.invoice_info_city,
                user_ic_initial: data.user.invoice_info_ico,
                user_dic_initial: data.user.invoice_info_dic,
              },
            };
          });
        });
    }
  }, []);

  const navigate = useNavigate();

  const account = () => {
    navigate("/ucet");
  };

  const profile = () => {
    navigate("/profil");
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleChange = (name, value, initialvalue, event) => {
    //console.log(event.target.value != event.target.initialvalue);
    //console.log(name, value, initialvalue);
    setState((state) => {
      return {
        ...state,
        user: {
          ...state.user,
          [name]: event.target.value,
        },
        [name + "_changed"]: event.target.value !== initialvalue,
      };
    });
  };

  const changePassword = (event) => {
    setState((state) => {
      return {
        ...state,
        password: event.target.value,
      };
    });
  };

  const changePasswordConfirmation = (event) => {
    setState((state) => {
      return {
        ...state,
        password_confirmation: event.target.value,
      };
    });
  };

  const saveField = (name) => {
    fetch(
      "https://chciuklizeno.cz/api/account/" +
      uid +
      "/set_" +
      name.substring(5) +       // get rid of "user_"
      "?token=" +
      token +
      "&" +
      name.substring(5) +
      "=" +
      state.user[name],
      {
        method: "POST",
      }
    ).then((response) => {
      if (response.status != 200) {
        setState((state) => {
          return {
            ...state,
            error_msg: "Při změně se vyskytla chyba.",
            severity: "error",
          };
        });
        return;
      }
      setState((state) => {
        return {
          ...state,
          user: {
            ...state.user,
            [name + "_initial"]: state.user[name],
          },
          [name + "_changed"]: false,
          error_msg: "Úspěšně změněno",
          severity: "success",
        };
      });
    });
  };

  const savePassword = () => {
    fetch(
      "https://chciuklizeno.cz/api/account/" +
      uid +
      "/set_password?token=" +
      token,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "*",
        },
        body: JSON.stringify({
          password: state.password,
          password_confirmation: state.password_confirmation,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.status != "ok") {
          setState((state) => {
            return {
              ...state,
              error_msg:
                "Heslo musí být 8 znaků dlouhé, obsahovat malé i velké písmeno a číslo.",
              severity: "error",
            };
          });
          return;
        }
        setState((state) => {
          return {
            ...state,
            password: "",
            password_confirmation: "",
            error_msg: "Heslo úspěšně změněno",
            severity: "success",
          };
        });
      });
  };

  const closeAlert = () => {
    setState((state) => {
      return { ...state, error_msg: null };
    });
    localStorage.removeItem("error_msg");
  };

  return (
    <ContentWrapper hideButtons={true} maxWidth={'md'}>
      {!state.alertClosed ? (
        <div>
          {state.error_msg != "" && state.error_msg != null ? (
            <Alert
              style={{
                position: "fixed",
                inset: "5px 5px auto 5px",
                zIndex: 1100000,
              }}
              severity={state.severity}
              variant="filled"
              onClose={closeAlert}
            >
              {state.error_msg}
            </Alert>
          ) : null}
        </div>
      ) : null}

      <Grid container justifyContent={'space-between'} style={{ marginBottom: 24 }}>
        <BackButton />
        <Button
          style={{
            whiteSpace: "nowrap",
            borderRadius: 19,
            boxShadow: "none",
            background: "#A30000",
            textTransform: "initial",
            fontFamily: "agrandir",
          }}
          color="error"
          variant="contained"
          onClick={logout}
        >
          <span style={{ position: "relative", top: 2 }}>Odhlásit se</span>
        </Button>
      </Grid>
      <LayoutCard>
        <Typography variant="h3" gutterBottom>
          Osobní údaje
        </Typography>
        <Grid container flexWrap={'nowrap'} alignItems={'center'} style={{ marginBottom: 30 }}>
          <TextField
            label="Email"
            name="user_email"
            variant="standard"
            className="w-full"
            style={{ marginRight: 30, }}
            value={state.user.user_email}
            data-initialvalue={state.user.user_email_initial}
            autoComplete="off"
            onChange={(event) =>
              handleChange(
                "user_email",
                state.user.user_email,
                state.user.user_email_initial,
                event
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          {state.user_email_changed ? (
            <Button
              variant="contained"
              size="small"
              style={{
                position: "relative",
                top: 15,
                borderRadius: 19,
                boxShadow: "none",
                background: "#004D58",
                textTransform: "initial",
                fontFamily: "agrandir",
              }}
              onClick={() => saveField("user_email")}
            >
              <span style={{ position: "relative", top: 2 }}>Uložit</span>
            </Button>
          ) : null}
        </Grid>
        <Grid container flexWrap={'nowrap'} alignItems={'center'} style={{ marginBottom: 30 }}>
          <TextField
            label="Telefon"
            name="user_phone"
            variant="standard"
            className="w-full"
            style={{ marginRight: 30 }}
            value={state.user.user_phone}
            data-initialvalue={state.user.user_phone_initial}
            autoComplete="off"
            onChange={(event) =>
              handleChange(
                "user_phone",
                state.user.user_phone,
                state.user.user_phone_initial,
                event
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          {state.user_phone_changed ? (
            <Button
              variant="contained"
              size="small"
              style={{
                position: "relative",
                top: 15,
                borderRadius: 19,
                boxShadow: "none",
                background: "#004D58",
                textTransform: "initial",
                fontFamily: "agrandir",
              }}
              onClick={() => saveField("user_phone")}
            >
              <span style={{ position: "relative", top: 2 }}>Uložit</span>
            </Button>
          ) : null}
        </Grid>
        <Typography variant="h6" style={{ display: "block", marginTop: 20 }}>
          Změnit heslo
        </Typography>
        <Grid container flexWrap={'nowrap'} alignItems={'center'} style={{ marginBottom: 30 }}>
          <TextField
            label="Heslo"
            name="password"
            type="password"
            variant="standard"
            className="w-full"
            style={{ marginRight: 30 }}
            onChange={changePassword}
            value={state.password}
            autoComplete="off"
          />
        </Grid>
        <Grid container flexWrap={'nowrap'} alignItems={'center'} style={{ marginBottom: 30 }}>
          <TextField
            label="Potvrdit heslo"
            name="password_confirmation"
            type="password"
            variant="standard"
            className="w-full"
            onChange={changePasswordConfirmation}
            value={state.password_confirmation}
            style={{ marginRight: 30 }}
            autoComplete="off"
          />
          {state.password != "" || state.password_confirmation != "" ? (
            <Button
              variant="contained"
              size="small"
              style={{
                position: "relative",
                top: 15,
                borderRadius: 19,
                boxShadow: "none",
                background: "#004D58",
                textTransform: "initial",
                fontFamily: "agrandir",
              }}
              onClick={savePassword}
            >
              <span style={{ position: "relative", top: 2 }}>Uložit</span>
            </Button>
          ) : null}
        </Grid>
      </LayoutCard>
      <LayoutCard>
        <Typography variant="h3" gutterBottom>
          Fakturační údaje
        </Typography>
        {/* <div style={{ marginBottom: 30 }}>
          <TextField
            label="Jméno"
            name="user_firstname"
            variant="standard"
            style={{ marginRight: 30 }}
            value={state.user.user_firstname}
            data-initialvalue={state.user.user_firstname_initial}
            autoComplete="off"
            onChange={(event) =>
              handleChange(
                "user_firstname",
                state.user.user_firstname,
                state.user.user_firstname_initial,
                event
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          {state.user_firstname_changed ? (
            <Button
              variant="contained"
              size="small"
              style={{
                position: "relative",
                top: 15,
                borderRadius: 19,
                boxShadow: "none",
                background: "#004D58",
                textTransform: "initial",
                fontFamily: "agrandir",
              }}
              onClick={() => saveField("user_firstname")}
            >
              <span style={{ position: "relative", top: 2 }}>Uložit</span>
            </Button>
          ) : null}
        </div>
        <div style={{ marginBottom: 30 }}>
          <TextField
            label="Příjmení"
            name="user_lastname"
            variant="standard"
            style={{ marginRight: 30 }}
            value={state.user.user_lastname}
            data-initialvalue={state.user.user_lastname_initial}
            autoComplete="off"
            onChange={(event) =>
              handleChange(
                "user_lastname",
                state.user.user_lastname,
                state.user.user_lastname_initial,
                event
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          {state.user_lastname_changed ? (
            <Button
              variant="contained"
              size="small"
              style={{
                position: "relative",
                top: 15,
                borderRadius: 19,
                boxShadow: "none",
                background: "#004D58",
                textTransform: "initial",
                fontFamily: "agrandir",
              }}
              onClick={() => saveField("user_lastname")}
            >
              <span style={{ position: "relative", top: 2 }}>Uložit</span>
            </Button>
          ) : null}
        </div> */}
        <Grid container flexWrap={'nowrap'} alignItems={'center'} style={{ marginBottom: 30 }}>
          <TextField
            label="Jméno a přijmení (název firmy)"
            name="user_invoice_name"
            variant="standard"
            className="w-full"
            style={{ marginRight: 30 }}
            value={state.user.user_invoice_name}
            data-initialvalue={state.user.user_invoice_name_initial}
            autoComplete="off"
            onChange={(event) =>
              handleChange(
                "user_invoice_name",
                state.user.user_invoice_name,
                state.user.user_invoice_name_initial,
                event
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          {state.user_invoice_name_changed ? (
            <Button
              variant="contained"
              size="small"
              style={{
                position: "relative",
                top: 15,
                borderRadius: 19,
                boxShadow: "none",
                background: "#004D58",
                textTransform: "initial",
                fontFamily: "agrandir",
              }}
              onClick={() => saveField("user_invoice_name")}
            >
              <span style={{ position: "relative", top: 2 }}>Uložit</span>
            </Button>
          ) : null}
        </Grid>
        <Grid container flexWrap={'nowrap'} alignItems={'center'} style={{ marginBottom: 30 }}>
          <TextField
            label="Ulice, č.p."
            name="user_street_and_number"
            variant="standard"
            className="w-full"
            style={{ marginRight: 30 }}
            value={state.user.user_street_and_number}
            data-initialvalue={state.user.user_street_and_number_initial}
            autoComplete="off"
            onChange={(event) =>
              handleChange(
                "user_street_and_number",
                state.user.user_street_and_number,
                state.user.user_street_and_number_initial,
                event
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          {state.user_street_and_number_changed ? (
            <Button
              variant="contained"
              size="small"
              style={{
                position: "relative",
                top: 15,
                borderRadius: 19,
                boxShadow: "none",
                background: "#004D58",
                textTransform: "initial",
                fontFamily: "agrandir",
              }}
              onClick={() => saveField("user_street_and_number")}
            >
              <span style={{ position: "relative", top: 2 }}>Uložit</span>
            </Button>
          ) : null}
        </Grid>
        <Grid container flexWrap={'nowrap'} alignItems={'center'} style={{ marginBottom: 30 }}>
          <TextField
            label="Město"
            name="user_city"
            variant="standard"
            className="w-full"
            style={{ marginRight: 30 }}
            value={state.user.user_city}
            data-initialvalue={state.user.user_city_initial}
            autoComplete="off"
            onChange={(event) =>
              handleChange(
                "user_city",
                state.user.user_city,
                state.user.user_city_initial,
                event
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          {state.user_city_changed ? (
            <Button
              variant="contained"
              size="small"
              style={{
                position: "relative",
                top: 15,
                borderRadius: 19,
                boxShadow: "none",
                background: "#004D58",
                textTransform: "initial",
                fontFamily: "agrandir",
              }}
              onClick={() => saveField("user_city")}
            >
              <span style={{ position: "relative", top: 2 }}>Uložit</span>
            </Button>
          ) : null}
        </Grid>
        <Grid container flexWrap={'nowrap'} alignItems={'center'} style={{ marginBottom: 30 }}>
          <TextField
            label="PSČ"
            name="user_zip"
            variant="standard"
            className="w-full"
            style={{ marginRight: 30 }}
            value={state.user.user_zip}
            data-initialvalue={state.user.user_zip_initial}
            autoComplete="off"
            onChange={(event) =>
              handleChange(
                "user_zip",
                state.user.user_zip,
                state.user.user_zip_initial,
                event
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          {state.user_zip_changed ? (
            <Button
              variant="contained"
              size="small"
              style={{
                position: "relative",
                top: 15,
                borderRadius: 19,
                boxShadow: "none",
                background: "#004D58",
                textTransform: "initial",
                fontFamily: "agrandir",
              }}
              onClick={() => saveField("user_zip")}
            >
              <span style={{ position: "relative", top: 2 }}>Uložit</span>
            </Button>
          ) : null}
        </Grid>
        <Grid container flexWrap={'nowrap'} alignItems={'center'} style={{ marginBottom: 30 }}>
          <TextField
            label="IČ"
            name="user_ic"
            variant="standard"
            className="w-full"
            style={{ marginRight: 30 }}
            value={state.user.user_ic}
            data-initialvalue={state.user.user_ic_initial}
            autoComplete="off"
            onChange={(event) =>
              handleChange(
                "user_ic",
                state.user.user_ic,
                state.user.user_ic_initial,
                event
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          {state.user_ic_changed ? (
            <Button
              variant="contained"
              size="small"
              style={{
                position: "relative",
                top: 15,
                borderRadius: 19,
                boxShadow: "none",
                background: "#004D58",
                textTransform: "initial",
                fontFamily: "agrandir",
              }}
              onClick={() => saveField("user_ic")}
            >
              <span style={{ position: "relative", top: 2 }}>Uložit</span>
            </Button>
          ) : null}
        </Grid>
        <Grid container flexWrap={'nowrap'} alignItems={'center'} style={{ marginBottom: 30 }}>
          <TextField
            label="DIČ"
            name="user_dic"
            variant="standard"
            className="w-full"
            style={{ marginRight: 30 }}
            value={state.user.user_dic}
            data-initialvalue={state.user.user_dic_initial}
            autoComplete="off"
            onChange={(event) =>
              handleChange(
                "user_dic",
                state.user.user_dic,
                state.user.user_dic_initial,
                event
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          {state.user_dic_changed ? (
            <Button
              variant="contained"
              size="small"
              style={{
                position: "relative",
                top: 15,
                borderRadius: 19,
                boxShadow: "none",
                background: "#004D58",
                textTransform: "initial",
                fontFamily: "agrandir",
              }}
              onClick={() => saveField("user_dic")}
            >
              <span style={{ position: "relative", top: 2 }}>Uložit</span>
            </Button>
          ) : null}
        </Grid>
      </LayoutCard>
    </ContentWrapper >
  );
}

export default AccountSettings;
