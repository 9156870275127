import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: "#004d58" }} />}
		{...props}
	/>
))(({ theme }) => ({
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
}));

export default function MobileMenuAccordion(props) {
	const [expanded, setExpanded] = React.useState('panel1');

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	return (
		<div style={{ display: "flex", flexDirection: "column", }}>
			<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ width: "min-content" }}>
					<Typography style={{
						fontFamily: "agrandir",
						fontSize: 20,
						color: "#004D58",
					}}>
						Informace
					</Typography>
				</AccordionSummary>
				<AccordionDetails
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}>
					{[
						{ title: 'O nás', url: '/o-nas', },
						{ title: 'Etika', url: '/eticky-kodex', },
						{ title: 'Poukázky', url: '/poukazky', },
						{ title: 'Média', url: '/media', },
						{ title: 'Časté dotazy', url: '/casto-kladene-otazky', },
						{ title: 'Kontakty', url: '/kontakt', },

						// { title: 'Často kladené otázky', url: '/faq', },
						// { title: 'Ceník', url: '/cenik', },
						// { title: 'Pomoc', url: '/pomoc', },
						// { title: 'Všeobecné obchodní podmínky', url: '/vseobecne-obchodni-podminky', },
						// { title: 'Ochrana osobních údajů', url: '/ochrana-osobnich-udaju', },
					].map(link =>
						<Link to={link.url} onClick={props.handleClose}>
							<MenuItem style={{
								fontFamily: "agrandir",
								fontSize: 16,
								color: "#004D58",
								textAlign: "center",
							}}>
								{link.title}
							</MenuItem>
						</Link>
					)}
				</AccordionDetails>
			</Accordion>
			{/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<AccordionSummary aria-controls="panel2d-content" id="panel2d-header" style={{ width: "min-content" }}>
					<Typography style={{
						fontFamily: "agrandir",
						fontSize: 20,
						color: "#004D58",
					}}>
						Služby
					</Typography>
				</AccordionSummary>
				<AccordionDetails
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}>
					{[
						{ title: 'O nás', url: '/o-nas', },
						{ title: 'Etika', url: '/eticky-kodex', },
						{ title: 'Poukázky', url: '/poukazky', },
						{ title: 'Média', url: '/media', },
						{ title: 'Kontakty', url: '/kontakt', },

						// { title: 'Často kladené otázky', url: '/faq', },
						// { title: 'Ceník', url: '/cenik', },
						// { title: 'Pomoc', url: '/pomoc', },
						// { title: 'Všeobecné obchodní podmínky', url: '/vseobecne-obchodni-podminky', },
						// { title: 'Ochrana osobních údajů', url: '/ochrana-osobnich-udaju', },
					].map(link =>
						<Link to={link.url} onClick={props.handleClose}>
							<MenuItem style={{
								fontFamily: "agrandir",
								fontSize: 16,
								color: "#004D58",
							}}>
								{link.title}
							</MenuItem>
						</Link>
					)}
				</AccordionDetails>
			</Accordion> */}
		</div>
	);
}