import { Alert, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';

const PayFromCreditButton = (props) => {
	const payFromCredit = () => {
		const uid = localStorage.getItem("uid");
		const token = localStorage.getItem("token");
		//console.log(props);
		fetch("https://chciuklizeno.cz/api/users/" + uid + "/pay_order/" + props.order_id + "?token=" + token, {
			method: "POST",
		})
			.then((response) => response.json())
			.then((data) => {
				window.location.reload(false);
				if (data.status == 'ok') {
					props.setAlert('Objednávka je zaplacena', 'success');
					props.paidHook();
				}
				else {
					props.setAlert('Někde nastala chyba', 'error');
				}
			});
	};

	return (
		<div style={{ display: "inline" }}>
			<Button
				variant="contained"
				size="small"
				onClick={payFromCredit}
				style={{
					borderRadius: 19,
					boxShadow: "none",
					background: "#8c5dbc",
					textTransform: "initial",
					fontFamily: "agrandir",
				}}
			>
				<span style={{ position: "relative", top: 2 }}>Zaplatit z peněženky</span>

			</Button>
		</div>
	);

};

export default PayFromCreditButton;