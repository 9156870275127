import { Button, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { METHOD_PRICE } from './constants';
import { useState } from 'react';

const DiscountField = (props) => {
	const handleChange = props.handleChange;
	const coupon = props.state.coupon;
	const isMobile = props.state.mobile;

	const [state, setState] = useState({
		coupon_message: null,
		coupon_bad: false,
	});

	const validateCoupon = () => {
		const package_discounts = {
			1: 100,
			2: 0,
			3: METHOD_PRICE,
		};

		var coupon = "non_existing_token";
		if (props.state.coupon != "" && props.state.coupon != null) coupon = props.state.coupon;
		fetch("https://chciuklizeno.cz/api/coupon/" + coupon + "/uid/" + localStorage.getItem("uid") + "?token=" + localStorage.getItem("token"))
			.then((response) => response.json())
			.then((data) => {
				if (data.status == "ok") {
					props.setCouponActiveGeneric({
						coupon: data.coupon.coupon_code,
						coupon_active: true,
						coupon_scalar: data.coupon.coupon_effect_scalar,
						coupon_active_on_package: data.coupon.coupon_effect_group == 2 ? false : true,    // group 1 includes 100 package discount, group 2 doesn't, 3 includes full discount
						coupon_package_discount: package_discounts[data.coupon.coupon_effect_group]
					});
					setState({
						coupon_message: "Slevový kód byl uplatněn",
						coupon_bad: false,
					});
					if (data.coupon.coupon_effect_group == 3) {
						props.wantCleaningPackage(true);
					}
				} else {
					props.setCouponActiveGeneric({
						coupon: "",
						coupon_active: false,
						coupon_scalar: 0,
						coupon_active_on_package: false,
						coupon_package_discount: 0,
					});
					setState({
						coupon_message: "Kód není validní",
						coupon_bad: true,
					});
				}
			});
	};

	return (

		<div style={{
			background: '#fff',
			boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
			padding: '16px 16px 24px 16px',
			borderRadius: '16px',
			marginBottom: 24,
		}}>
			<Typography variant="h3" style={{ marginBottom: 20 }}>
				Slevový kód
			</Typography>
			<Grid container justifyContent={"space-between"} alignItems={"flex-start"} direction={"row"} wrap={"nowrap"}>
				{/* label="Slevový kód" */}
				<TextField
					variant="outlined"
					size='small'
					color="primary"
					name="coupon"
					onChange={handleChange}
					value={coupon}
					error={state.coupon_bad}
					helperText={state.coupon_message}
					sx={{
						minWidth: isMobile ? 175 : 300,
						marginRight: '20px',
						width: '100%',
						'.MuiInputBase-root': {
							borderRadius: '100px',
						},
					}}
				/>
				<Button variant="contained" style={{
					background: "#004d58",
					borderRadius: 19,
					fontSize: 14,
					width: '50%',
					height: 37,
				}} onClick={validateCoupon}>
					Uplatnit
				</Button>
			</Grid>
		</div>
	);
};

export default DiscountField;