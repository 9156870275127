import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

function AutocompleteCity(props) {
	const [suggestions, setSuggestions] = useState([
		// { label: 'Prague, Česko', place_id: 'ChIJi3lwCZyTC0cRkEAWZg-vAAQ' },
		// { label: 'Beroun, Česko', place_id: 'ChIJP7q7Wj6lC0cRMLoUZg-vAAQ' },
		// { label: 'Černošice, Česko', place_id: 'ChIJDVGWSYaXC0cRI8UtcrZW6GY' }
	]);
	const [validation, setValidation] = useState({
		locationSet: false,
		locationMessage: null,
	});
	const [selected, setSelected] = useState(null);
	useEffect(() => {
		if (localStorage.getItem('availability')) {
			const availability = JSON.parse(localStorage.getItem('availability'));
			const data = {
				label: availability.city_name,
				place_id: availability.zone_place_id,
			};
			setSuggestions([data]);
			setSelected(data);
		}
	}, []);

	const autocomplete = (city_prefix) => {
		const url = `https://chciuklizeno.cz/api/autocomplete/v2/${city_prefix}`;
		fetch(url).then((response) => response.json())
			.then((data) => {
				setSuggestions(data.results);
				// if (!data.results.includes(item => item.place_id == selected.place_id)) {
				// 	setSelected(null);
				// }
			});
	};

	const place_details = (place_id) => {
		if (place_id == null || place_id == "")
			console.error("There is no place_id provided from selected option.");

		setSelected(suggestions.find(item => item.place_id == place_id));

		const url = `https://chciuklizeno.cz/api/place/v2/${place_id}`;
		fetch(url).then((response) => response.json())
			.then((data) => {
				return {
					place_id: place_id,
					postal_code: data.place.postal_code ? data.place.postal_code.replaceAll(" ", "") : null,
					city_name: (data.place.locality ?? data.place.neighborhood) ?? data.place.sublocality_level_1,
				}
			})
			.then(data => verify_availability(data.place_id, data.postal_code, data.city_name))
			.then(result => {
				//console.log(result);
				let availability = {
					zone_id: null,
					actively_cleaning: false,
					min_duration: null,
					travel_fee: null,
					open_since: "2099-12-31",
					zip: null,
					city_name: null,
					zone_place_id: null,
					region_id: null,
				};
				if (result.status == 'ok') {
					availability.zone_id = result.zone_id;
					availability.actively_cleaning = result.actively_cleaning;
					availability.min_duration = result.min_duration;
					availability.travel_fee = result.travel_fee;
					availability.open_since = result.open_since;
					availability.zip = result.zip;
					availability.city_name = result.city_name;
					availability.zone_place_id = result.zone_place_id;
					availability.region_id= result.region_id;
				}
				localStorage.setItem('availability', JSON.stringify(availability));
				localStorage.setItem("zip", result.zip);
				localStorage.setItem("city_name", result.city_name);

				setValidation((validation) => ({
					...validation,
					...{
						locationSet: true,
					}
				}));
				// refreshes app
				props.handleChange({
					target: {
						name: "arb",
						value: true,
					}
				});
				props.handleChange({
					target: {
						name: "city",
						value: result.city_name,
					}
				});
				props.handleChange({
					target: {
						name: "zip",
						value: result.zip,
					}
				});
				props.setCity({
					target: {
						name: "city",
						value: result.city_name,
					}
				});
			});
	};

	const verify_availability = (place_id, postal_code, city_name) => {
		const url = `https://chciuklizeno.cz/api/verify_city/${place_id}/${postal_code}/${city_name}`;
		const response = fetch(url).then((response) => response.json());

		return response;
	};

	return (
		<Autocomplete
			disableClearable
			disablePortal
			color="secondary"
			label="Město / Obec"
			openText="Ukázat možnosti"
			closeText="Zavřít"
			style={{
				backgroundColor: "#FBFDFC",
				//margin: "10px",
				//width: 318,
				borderRadius: "6px",
			}}
			// error={state.zip_message !== null}
			// helperText={state.zip_message !== null ? state.zip_message : null}
			renderInput={
				(params) =>
					<TextField
						variant="standard"
						{...params}
						label="Město / Obec"
						onChange={(e) => {
							if (e.target.value != null) {
								autocomplete(e.target.value);
							}
						}}
					/>
			}
			value={selected}
			options={suggestions}
			getOptionLabel={(option) => option.label || ""}
			isOptionEqualToValue={(option, value) => option.place_id == value.place_id}
			onChange={(e, value) => place_details(value.place_id)}
			noOptionsText={'Zadejte město'}
		/>
	);
}

export default AutocompleteCity;