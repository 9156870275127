import {
	Alert,
	Badge,
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	Collapse,
	FormControlLabel,
	Grid,
	IconButton,
	ThemeProvider,
	Typography,
	createTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay, TimePicker, csCZ } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/cs';
import CloseIcon from '@mui/icons-material/Close';

const theme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
	},
	typography: {
		fontFamily: 'agrandir',
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
				},
			},
		},
	},
});

function OrderEdit(props) {
	const [alert, setAlert] = useState({
		message: null,
		severity: null,
	});

	return (
		<>
			<Typography variant="h3" style={{ marginBottom: 30 }}>
				Změny v objednávce
			</Typography>
			<Collapse in={alert.message != null}>
				<Alert
					severity={alert.severity ?? 'success'}
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setAlert({
									message: null,
								});
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					style={{ margin: '0 0 20px 0' }}
				>
					{alert.message}
				</Alert>
			</Collapse>
			<Grid
				container
				flexDirection={'column'}
				alignItems={'flex-start'}
				spacing={4}
				style={{ width: '100%' }}
			>
				<EditTime {...props} setAlert={setAlert} />
				{/* Add more edit components */}
			</Grid>
		</>
	);
}

function EditTime(props) {
	const [date, setDate] = useState(props.order.order_date);
	const [start, setStart] = useState(props.order.begin_as_int);
	const [exactCapacities, setExactCapacities] = useState([]);
	const [anyCapacities, setAnyCapacities] = useState([]);
	const [loading, setLoading] = useState(false);
	const [timeSlots, setTimeSlots] = useState([]);
	const [isConfirmed, setIsConfirmed] = useState(
		props.order.order_scheduled == null
	); // needs confirmation if scheduled order

	const api_call = () => {
		const url =
			'https://chciuklizeno.cz/api/order_change/' +
			localStorage.getItem('uid') +
			'/time';
		const params = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				order_id: props.order.order_id,
				token: localStorage.getItem('token'),
				proposed_date: date,
				proposed_start: start,
			}),
		};

		fetch(url, params)
			.then(response => response.json())
			.then(data => {
				if (data.status == 'ok') {
					props.setAlert({
						message: 'Objednávka úspěšně změněna.',
						severity: 'success',
					});
				} else {
					props.setAlert({
						message:
							'Něco se nepovedlo. Zkuste nás prosím se změnou kontaktovat.',
						severity: 'error',
					});
				}
				setTimeSlots([]);
				props.reload();
			})
			.catch(error => {
				console.error('Error:', error);
			});
	};

	const get_capacity = month => {
		setLoading(true);
		const url = 'https://chciuklizeno.cz/api/capacity-options';

		const params = {
			date: month.format('YYYY-MM-DD'),
			order_id: props.order.order_id,
		};

		const queryString = new URLSearchParams(params).toString();

		fetch(`${url}?${queryString}`, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		})
			.then(response => response.json())
			.then(data => {
				setAnyCapacities(Object.values(data.any_time));
				setExactCapacities(Object.values(data.exact_time));
				setLoading(false);
			})
			.catch(error => {
				console.error('Error:', error);
			});
	};

	useEffect(() => {
		get_capacity(dayjs(props.order.order_date));
	}, []);

	const pickedDate = date => {
		setDate(date.format('YYYY-MM-DD'));

		const zones = [
			'ChIJi3lwCZyTC0cRkEAWZg-vAAQ', // Praha
			'ChIJP7q7Wj6lC0cRMLoUZg-vAAQ', // Beroun
			'ChIJEVE_wDqUEkcRsLEUZg-vAAQ', // Brno
			'ChIJ3azMg13wEkcRUCsWZg-vAAQ', // Vyškov
			'ChIJl9VyMea8EkcRkAoVZg-vAAQ', // Hlína
		];

		console.log(props.order);

		fetch('https://chciuklizeno.cz/api/capacity/v4.5', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				duration: props.order.order_estimate,
				place_id: zones[props.order.apartment_zone - 1], //  JSON.parse(localStorage.getItem('availability'))?.zone_place_id || "NEVYPLNENO-PLANNING",
				days: [date.format('YYYY-MM-DD')],
				region_id: props.order.apartment_zone,
			}),
			redirect: 'follow',
		})
			.then(response => response.json())
			.then(result => {
				const slots = result[0].slots ?? [];
				if (slots.length <= 0) {
					props.setAlert({
						message: 'Není kapacita',
						severity: 'error',
					});
				}
				setTimeSlots(slots);
			})
			.catch(error => console.error('error', error));
	};

	const formatTime = slot => {
		return (
			(slot.start + ':00')?.replace('.5:00', ':30') +
			'-' +
			(slot.end + ':00')?.replace('.5:00', ':30')
		);
	};

	return (
		<Grid item style={{ width: '100%' }}>
			<ThemeProvider theme={theme}>
				<Grid container alignItems={'center'}>
					<Typography variant="body1" style={{ marginBottom: 10 }}>
						Pokud to není nezbytně nutné, moc Vás prosíme o
						nepřesouvání úklidů na poslední chvíli. Držíme pro Vás
						kapacitu našich hospodyň, které s úklidem dopředu
						počítají a při přeplánování během 48-72 hodin před
						začátkem se výrazně snižuje šance na opětovné naplnění
						této kapacity.
					</Typography>
					<Typography variant="body1" style={{ marginBottom: 10 }}>
						Moc děkujeme.
					</Typography>
					<div style={{ height: 20 }} />
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale={'cs'}
						localeText={
							csCZ.components.MuiLocalizationProvider.defaultProps
								.localeText
						}
					>
						<DatePicker
							label="Datum"
							defaultValue={dayjs(props.order.order_begin)}
							format={'D. M. YYYY'}
							disablePast
							onChange={pickedDate}
							onMonthChange={get_capacity}
							loading={loading}
							views={['day']}
							slots={{
								day: PickerDay,
							}}
							slotProps={{
								day: {
									anyCapacities: anyCapacities,
									exactCapacities: exactCapacities,
								},
								textField: {
									readOnly: true,
								},
							}}
							renderLoading={() => <CircularProgress />}
						/>
					</LocalizationProvider>
					<Grid container gap={1} style={{ margin: '30px 0' }}>
						{timeSlots.map((slot, index) => (
							<Grid item>
								<Chip
									key={index}
									label={formatTime(slot)}
									color="success"
									variant={
										slot.start == start
											? 'contained'
											: 'outlined'
									}
									onClick={() => {
										setStart(slot.start);
									}}
									time={formatTime(slot)}
									id={slot.id}
								/>
							</Grid>
						))}
					</Grid>
					{timeSlots.length > 0 &&
						props.order.order_scheduled != null && (
							<FormControlLabel
								control={
									<Checkbox
										checked={isConfirmed}
										onChange={e =>
											setIsConfirmed(e.target.checked)
										}
									/>
								}
								label="Souhlasím s příchodem náhradní hospodyně pokud moje obvyklá nebude moci."
							/>
						)}
					{timeSlots.length > 0 && isConfirmed && (
						<Grid container justifyContent={'flex-end'}>
							<Button variant="contained" onClick={api_call}>
								Přeplánovat
							</Button>
						</Grid>
					)}
				</Grid>
			</ThemeProvider>
		</Grid>
	);
}

function PickerDay(props) {
	const {
		anyCapacities = [],
		exactCapacities = [],
		day,
		outsideCurrentMonth,
		...other
	} = props;

	let emoji = undefined;
	switch (true) {
		case props.outsideCurrentMonth:
			emoji = undefined;
			break;
		case exactCapacities[props.day.date() - 1]:
			emoji = '✅';
			break;
		case anyCapacities[props.day.date() - 1]:
			// emoji = '🕐';
			emoji = '✅';
			break;
		default:
			emoji = '❌';
			break;
	}

	return (
		<Badge
			key={props.day.toString()}
			overlap="circular"
			badgeContent={emoji}
		>
			<PickersDay
				{...other}
				outsideCurrentMonth={outsideCurrentMonth}
				day={day}
			/>
		</Badge>
	);
}

export default OrderEdit;
