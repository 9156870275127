import React, { useRef, useState } from 'react';
import useIsMobile from "./lib/Responsive";
import { Collapse } from '@mui/material';
import { useNavigate } from 'react-router';

const LeadFormV3 = ({ standalone = true }) => {
	const isMobile = useIsMobile();
	const navigate = useNavigate();

	const currentRequestRef = useRef(0); // Tracks the most recent request
	const [loading, setLoading] = useState(false);

	// autocomplete suggestions
	const [zones, setZones] = useState([]);
	const [activeSuggestion, setActiveSuggestion] = useState(-1);

	// zone
	const [zoneId, setZoneId] = useState(null);
	const [zoneLabel, setZoneLabel] = useState('');
	const [referenceZoneLabel, setReferenceZoneLabel] = useState(''); // for determining if zone label was changed

	//email
	const [email, setEmail] = useState('');

	// validation
	const zoneValid = zoneId != null && referenceZoneLabel != '' && referenceZoneLabel == zoneLabel;
	const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	const [errors, setErrors] = useState([]);

	// sending
	const [sendLoading, setSendLoading] = useState(false);
	const [alternativePage, setAlternativePage] = useState(false);

	const send = async () => {
		if (sendLoading) return;

		setErrors([]);
		if (!zoneValid) { setErrors((prev) => [...prev, 'Zvolte prosím obec ve které bydlíte.']); return; }
		if (!emailValid) { setErrors((prev) => [...prev, 'Zadejte prosím váš email.']); return; }

		const referrer = document.referrer;

		setSendLoading(true);
		setAlternativePage(false);

		const url = 'https://chciuklizeno.cz/api/users/v2';

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: email,
				zone_id: zoneId,
				referrer: referrer,
			}),
		});

		if (response.status !== 200) {
			setSendLoading(false);
			try {
				const error = await response.json();
				if (error.side_page) {
					setAlternativePage(true);
				}
				setErrors([error.message]);
			} catch (error) {
				setErrors(['Nastala chyba na naší straně.']);
			}
			return;
		}

		const data = await response.json();

		console.log(data)

		localStorage.setItem('uid', data.user_id);
		localStorage.setItem('token', data.token);
		//* optional / legacy
		localStorage.setItem('email', email);
		localStorage.setItem('first_time', 'yes');
		localStorage.setItem('zip', data.zip);
		localStorage.setItem('city_name', data.city_name);
		localStorage.setItem('availability', JSON.stringify(data.availability));

		setSendLoading(false);

		navigate('/objednavka');
	};

	const autocomplete = (prefix) => {
		setLoading(true);
		const currentRequest = ++currentRequestRef.current; // Increment and store the current request identifier
		const url = `${process.env.REACT_APP_API_URL}/autocomplete?prefix=${prefix}`;
		fetch(url)
			.then((response) => {
				if (!response.ok) throw new Error('network');
				return response.json();
			})
			.then((data) => {
				// Only update suggestions if the response corresponds to the most recent request
				if (currentRequest === currentRequestRef.current) {
					setZones(data);
					// if (data.status == 'ok') {
					// 	setZones(data.zones);
					// 	// setErrorMessage(null);
					// }
					// else {
					// 	setZones([]);
					// 	throw new Error(data.message);
					// }
				}
			})
			.catch((error) => {
				if (currentRequest === currentRequestRef.current) {
					switch (error.message) {
						case 'network':
							// setErrorMessage('Problém se sítí.');
							break;

						default:
							console.log(error);
							break;
					}
				}
			})
			.finally(() => {
				if (currentRequest === currentRequestRef.current) {
					setLoading(false)
				}
			});
	};

	const handleChange = (e) => {
		const value = e.target.value;
		autocomplete(value);
		setZoneLabel(value);
	};

	const handleClick = (suggestion) => {
		setZoneLabel(suggestion.label);
		setZoneId(suggestion.zone_id);
		setReferenceZoneLabel(suggestion.label);
		setZones([]);
		setActiveSuggestion(null);
	}

	const handleKeyDown = (e) => {
		if (e.key === 'ArrowDown' || e.key === 'Tab') {
			e.preventDefault();
			setActiveSuggestion((prev) => ((prev ?? 0) + 1) % zones.length);
		} else if (e.key === 'ArrowUp') {
			setActiveSuggestion((prev) => ((prev ?? 0) === 0 ? zones.length - 1 : (prev ?? 0) - 1));
		} else if (e.key === 'Enter') {
			e.preventDefault();
			if (activeSuggestion >= 0) {
				handleClick(zones[activeSuggestion]);
			}
		}
	};

	// click field out of focus
	const handleBlur = () => {
		setTimeout(() => {
			setZones([]);
		}, 100);
	};

	const gridStyle = isMobile ?
		{ display: 'grid', gridTemplateColumns: 'auto', gap: 24, padding: 36 } :
		{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: 48, padding: 48 };

	return (
		<div style={{ display: 'flex', background: '#004d58', justifyContent: 'center', alignItems: 'center', height: standalone ? '100vh' : 'initial' }}>
			<div>
				<div style={{ ...gridStyle, background: '#fbfdfc', borderRadius: 30 }}>
					<div style={{}}>
						<h1 style={{ color: '#004d58', fontFamily: 'agrandir', fontSize: isMobile ? 24 : 36, lineHeight: '1.25em', textAlign: isMobile ? 'center' : 'left', marginBottom: isMobile ? 16 : 24 }}>
							Zjistěte cenu <br />
							vašeho Youklidu
						</h1>
						<p style={{ color: '#004d58', fontFamily: 'graphik_regular', fontSize: isMobile ? 14 : 16, textAlign: isMobile ? 'center' : 'left' }}>
							Chcete vědět, zda u vás uklízíme?<br />
							Zadejte níže uvedené údaje a<br />
							zobrazíme vám ihned kalkulaci.
						</p>
					</div>
					<div>
						<div style={{ display: 'grid', gridTemplateRows: 'auto 1fr 1fr 1fr auto', gridTemplateColumns: 'auto', gap: 16, width: isMobile ? 'calc(100vw - 72px)' : 340, maxWidth: 400, margin: 'auto' }}>
							<div>
								<Collapse in={errors.length > 0}>
									<div style={{ background: '#ffd2d2', color: '#8f1f1f', borderRadius: 21, padding: '8px 16px', width: '100%', }}>
										{errors.map(error => <>{error}</>)} {alternativePage &&
											<>
												Máte přesto zájem o úklid? <br />
												<a href="/co-delat-kdyz-u-me-neuklizite" style={{ textDecoration: 'underline' }}>Podívejte se co s tím můžete udělat.</a>
												<svg xmlns="http://www.w3.org/2000/svg" class="inline" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#8f1f1f" fill="none" strokeLinecap="round" strokeLinejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none" />
													<path d="M5 12l14 0" />
													<path d="M15 16l4 -4" />
													<path d="M15 8l4 4" />
												</svg>
											</>
										}
									</div>
								</Collapse>
							</div>
							<div style={{ position: 'relative' }}>
								<InputIconWrapper icon={loading ? <LoadingIcon /> : (zoneValid && <CheckIcon />)}>
									<input
										type="text"
										value={zoneLabel}
										onChange={handleChange}
										onKeyDown={handleKeyDown}
										onBlur={handleBlur}
										placeholder="Město / Obec"
										style={{
											padding: '8px 16px', borderRadius: 100, border: '1px solid #aaaaaa', outlineColor: '#004d58', fontFamily: 'graphik_regular', width: '100%'
										}}
									/>
								</InputIconWrapper>
								{zones.length > 0 && (
									<>
										<style>
											{`
												.autocomplete-active{
													background: #e8def2;
												}

												.autocomplete-items div{
													transition: 0.1s;
												}

												.autocomplete-items div:first-child{
													border-top-left-radius: 21px;
													border-top-right-radius: 21px;
												}

												.autocomplete-items div:last-child{
													border-bottom-left-radius: 21px;
													border-bottom-right-radius: 21px;
												}

												.autocomplete-items div:hover{
													background: #f4eff8;
												}
											`}
										</style>
										<div className="autocomplete-items"
											style={{ position: 'absolute', border: '1px solid #aaaaaa', zIndex: 99, top: '100%', left: 0, right: 0, background: '#ffffff', borderRadius: 21 }}
										>
											{zones.map((suggestion, index) => (
												<div
													key={index}
													className={index === activeSuggestion ? 'autocomplete-active' : ''}
													onMouseDown={() => handleClick(suggestion)}
													style={{
														cursor: 'pointer', padding: 10, borderTop: index != 0 ? '1px solid #d6d6d6' : ''
													}}
												>
													{suggestion.label}
												</div>
											))}
										</div>
									</>
								)}
							</div>
							<InputIconWrapper icon={emailValid ? <CheckIcon /> : <RequiredIcon />}>
								<input type="text" placeholder='Email' onChange={(e) => setEmail(e.target.value)} style={{
									padding: '8px 16px', borderRadius: 100, border: '1px solid #aaaaaa', outlineColor: '#004d58', fontFamily: 'graphik_regular', width: '100%'
								}} />
							</InputIconWrapper>
							<button onClick={send} style={{
								background: '#8c5cbc', border: '1px solid #8c5cbc', color: '#ffffff', borderRadius: 100, fontFamily: 'agrandir'
							}}>
								{!sendLoading ?
									<span style={{ position: 'relative', top: 2 }}>
										Kalkulace a objednávka
									</span>
									:
									<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										<LoadingIcon color={'#ffffff'} />
									</div>
								}
							</button>
							<p style={{ color: '#004d58', fontFamily: 'graphik_regular', fontSize: 14 }}>
								Už jste u nás objednávali? <a href="/prihlaseni" style={{ textDecoration: 'underline', color: '#004d58' }}>Přihlaste se.</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div >
	);
};

const InputIconWrapper = ({ children, icon }) => {
	return (
		<div style={{ position: 'relative', width: '100%' }}>
			{children}
			<div style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: 10, top: 0, bottom: 0 }} >
				{icon}
			</div>
		</div>
	);
};

const LoadingIcon = ({ color = '#9e9e9e' }) => {
	return (
		<>
			<style>
				{`
					@keyframes spin {
						0 % { transform: rotate(0deg); }
						100% {transform: rotate(360deg); }
					}

					.icon-tabler-loader {
						animation: spin 2s linear infinite;
					}
				`}
			</style>
			<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-loader" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke={color} fill="none" strokeLinecap="round" strokeLinejoin="round">
				<path stroke="none" d="M0 0h24v24H0z" fill="none" />
				<path d="M12 6l0 -3" />
				<path d="M16.25 7.75l2.15 -2.15" />
				<path d="M18 12l3 0" />
				<path d="M16.25 16.25l2.15 2.15" />
				<path d="M12 18l0 3" />
				<path d="M7.75 16.25l-2.15 2.15" />
				<path d="M6 12l-3 0" />
				<path d="M7.75 7.75l-2.15 -2.15" />
			</svg>
		</>
	);
};

const RequiredIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-asterisk" style={{ marginRight: 6 }} width="12" height="12" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff2825" fill="none" strokeLinecap="round" strokeLinejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none" />
			<path d="M12 12l8 -4.5" />
			<path d="M12 12v9" />
			<path d="M12 12l-8 -4.5" />
			<path d="M12 12l8 4.5" />
			<path d="M12 3v9" />
			<path d="M12 12l-8 4.5" />
		</svg>
	);
};

const CheckIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00b341" fill="none" strokeLinecap="round" strokeLinejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none" />
			<path d="M5 12l5 5l10 -10" />
		</svg>
	);
};

export default LeadFormV3;