import { CircularProgress, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Transactions = () => {
	const [state, setState] = useState({
		transactions: [],
		loaded: false,
	});

	useEffect(() => {
		const uid = localStorage.getItem("uid");
		const token = localStorage.getItem("token");
		fetch("https://chciuklizeno.cz/api/users/" + uid + "/transactions?token=" + token, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((data) => {
				setState({ transactions: data.transactions, loaded: true });
			});
	}, []);

	return (
		<div>
			{state.loaded ?
				<div>
					{
						state.transactions == [] ?
							<Typography variant="h3">
								Neexistují žádné transakce
							</Typography>
							:
							<TransactionsTable transactions={state.transactions} />
					}
				</div>
				:
				<Table aria-label="collapsible table" sx={{ minWidth: "max-content" }}>
					<TableHead>
						<TableRow style={{ background: "#f5f5f5" }}>
							<TableCell>Datum</TableCell>
							<TableCell>Transakce</TableCell>
							<TableCell align="right">Částka</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{[0, 1].map((row, index) => (
							<TableRow key={index}>
								<TableCell>
									<Skeleton />
								</TableCell>
								<TableCell>
									<Skeleton />
								</TableCell>
								<TableCell>
									<Skeleton />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			}

		</div>
	);
};

const TransactionsTable = (props) => {
	return <TableContainer component={Paper}>
		<Table aria-label="collapsible table" sx={{ minWidth: "max-content" }}>
			<TableHead>
				<TableRow style={{ background: "#f5f5f5" }}>
					<TableCell>Datum</TableCell>
					<TableCell>Účel</TableCell>
					<TableCell align="right">Částka</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{
					props.transactions.map(item =>
						<TableRow>
							<TableCell style={{ color: "#000" }}> {new Date(item.created_at).toLocaleDateString('cz-cs', { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" })} </TableCell>
							<TableCell style={{ color: "#000" }}><PurposeSwitch mode={item.transaction_mode} /></TableCell>
							<TableCell align="right"> <span style={{ color: item.transaction_amount == 0 ? "black" : item.transaction_amount < 0 ? "#a33135" : "#006D58" }}> {item.transaction_amount} Kč</span></TableCell>
						</TableRow>
					)
				}
			</TableBody>
		</Table>
	</TableContainer >;
}

const PurposeSwitch = (props) => {
	switch (props.mode) {
		case "CHARGE":
			return "Nabití peněženky";
		case "REFUND":
			return "Storno";
		case "SPEND":
			return "Platba objednávky";
		case "BONUS":
			return "Věrnostní bonus";
		case "SUB_BONUS":
			return "Manuální úprava";
		case "WITHDRAW":
			return "Vrácené peníze";
		case "SURCHARGE":
			return "Doplatek";
		case "OVERPAYMENT":
			return "Vrácení přeplatku";
		default:
			break;
	}
	return '';
}

export default Transactions;


/* {
	<TableBody>
		{[0, 1, 2].map((row, index) => (
			<TableRow key={index}>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
				<TableCell>
					<Skeleton />
				</TableCell>
			</TableRow>
		))}
	</TableBody>
)
} */