import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Input, CircularProgress, Alert, ThemeProvider, createTheme, Collapse } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ContentWrapper from './Wrapper/ContentWrapper';
import OutlinedInput from '@mui/material/OutlinedInput';
import BackButton from './BackButton';
import LayoutCard from './Components/LayoutCard';

const theme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
		secondary: {
			main: '#004d58',
		},
	},
	typography: {
		fontFamily: 'agrandir',
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
					height: 56,
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					height: 56,
				},
			},
		},
	},
});

const ApplyVoucher = (props) => {
	const navigate = useNavigate();
	const [code, setCode] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [success, setSuccess] = useState(null);

	const apply = () => {
		setLoaded(false);
		setSuccess(null);
		fetch('https://chciuklizeno.cz/api/voucher_apply', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				user_id: localStorage.getItem('uid'),
				code: code
			})
		})
			.then(response => response.json())
			.then(data => {
				if (data.status == 'ok') {
					setSuccess(true);
				}
				setLoaded(true);
			})
			.catch(error => {
				setSuccess(false);
				setLoaded(true);
			});
	};

	return (
		<ContentWrapper hideButtons={true} maxWidth={'md'}>
			<BackButton />
			<LayoutCard>
				<Typography variant="h3" gutterBottom>
					Uplatnit voucher
				</Typography>
				<div style={{ height: 20 }} />
				<ThemeProvider theme={theme}>
					<Grid container columnSpacing={6} alignItems={'flex-start'}>
						<Grid item xs={12} md={6}>
							<TextField
								label="Kód z voucheru"
								type="text"
								value={code}
								onChange={(e) => { setCode(e.target.value) }}
								variant="outlined"
								fullWidth
								helperText={null}
								error={false}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Button variant="contained" color="primary" onClick={apply} fullWidth>
								<span style={{ position: 'relative', top: 2 }}>Převést voucher do kreditu</span>
							</Button>
						</Grid>
					</Grid>
				</ThemeProvider>
				<div style={{ height: 20 }} />
				<Collapse in={loaded}>
					{success == true ?
						<Alert severity='success'>
							Voucher úspěšně uplatněn. Kredit najdete ve vaší peněžence.
						</Alert>
						:
						<Alert severity='warning'>
							Při uplatnění došlo k chybě. Nejčasteji se stává, že voucheru vyprší platnost nebo že je již uplatněný.
						</Alert>
					}
				</Collapse>
			</LayoutCard>
			<div style={{ height: 60 }} />
		</ContentWrapper>
	);
};

export default ApplyVoucher;