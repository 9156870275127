import { Button } from "@mui/material";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

function HelpButton(props) {
  const [state, setState] = useState({
    phone: true,
    mobile: isMobile,
  });

  return (
    <div
      style={{
        position: "fixed",
        right: state.mobile ? -71 : -111,
        top: "50vh",
        transform: "rotate(-90deg)",
        zIndex: 1,
      }}
    >
      <a
        href="/casto-kladene-otazky"
        target="_blank" rel="noopener noreferrer"
        style={{ color: "#fff", fontSize: state.mobile ? 14 : 20 }}
      >
        <div
          variant="contained"
          style={{
            borderRadius: 0,
            background: "#8C5CBC",
            boxShadow: "none",
            fontSize: state.mobile ? 14 : 20,
            fontFamily: "agrandir",
            textTransform: "initial",
            width: state.mobile ? 180 : 268,
            textAlign: "center",
            padding: 10,
          }}
        >
          <span style={{ position: "relative", top: 2 }}>
            Potřebujete poradit?
          </span>
        </div>
      </a>
    </div>
  );
}

export default HelpButton;
