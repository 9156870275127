import { Typography, Container, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Balance from "./Balance";
import ContentWrapper from "./Wrapper/ContentWrapper";
import ProfileAd from "./ProfileAd";
import ScheduleCalendar from "./ScheduleCalendar";
import OrderTableNew from "./OrderTableNew";

function Profile(props) {
  let { uid, token } = useParams();
  const navigate = useNavigate();
  let dataOK = true;
  if (!uid) {
    if (localStorage.getItem("uid")) {
      uid = localStorage.getItem("uid");
    } else {
      // goto login
      dataOK = false;
    }
  }
  if (!token) {
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      // goto login
      dataOK = false;
    }
  }

  if (dataOK) {
    localStorage.setItem("uid", uid);
    localStorage.setItem("token", token);
    //localStorage.setItem("first_time", "no");
  }

  useEffect(() => {
    if (!dataOK) {
      navigate("/login");
    } else {
      fetch("https://chciuklizeno.cz/api/users/" + uid + "?token=" + token)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 'ok') {
            const is_client = data.user.is_client;
            if (is_client) {
              localStorage.setItem("first_time", "no");
            }
            else {
              localStorage.removeItem('token');
              localStorage.setItem("error_msg", "Ještě jste nevyplnili první objednávku, nemůžeme vás tedy pustit do profilu.");
              navigate("/");
            }
          } else {
            navigate("/login");
          }
        });
    }
  }, []);

  return (
    <ContentWrapper hideButtons={true} maxWidth={'md'} showFAQ={false}>
      <ProfileMenu />
      <Balance displayLink={true} />
      {/* <OrderTable /> */}
      <ProfileAd ad_version={2} />
      <OrderTableNew />
      <ProfileAd ad_version={1} />
      <ScheduleCalendar />
    </ContentWrapper>
  );
}

function ProfileMenu(props) {
  const navigate = useNavigate();

  return (
    <Grid container justifyContent={'space-between'} alignItems={'center'} style={{ marginBottom: 24 }}>
      <Button
        style={{
          whiteSpace: "nowrap",
          borderRadius: 19,
          boxShadow: "none",
          background: "#004D58",
          textTransform: "initial",
          fontFamily: "agrandir",
        }}
        variant="contained"
        onClick={() => navigate('/objednavka')}
      >
        <span style={{ position: "relative", top: 2 }}>Nová objednávka</span>
      </Button>
      <Button
        onClick={() => navigate('/ucet')}
        className='gray_button'
        style={{
          display: "block",
          backgroundColor: "transparent",
          //border: "1px solid #004d58",
          borderRadius: "400px",
          fontFamily: "agrandir",
          fontSize: 16,
          textTransform: "initial",
          boxShadow: "none",
          color: "#004d58",
          marginLeft: 24,
        }}
        variant="contained"
      >
        <img src="/img/settings.svg" alt="nastavení" style={{ display: "inline", marginRight: 9, width: 22 }} />
        <span style={{ position: "relative", top: "2px" }}>Nastavení účtu</span>
      </Button>
    </Grid>);
}

export default Profile;
