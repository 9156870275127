import { Alert, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

const CountDown = (props) => {
	const navigate = useNavigate();
	const [time, setTime] = useState(null);
	const [deadline, setDeadline] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [expired, setExpired] = useState(false);
	const [paid, setPaid] = useState(false);

	const secondsToTime = (seconds) => {
		setExpired(seconds <= 0);

		let out = "";

		if (seconds >= 60 * 60) {
			const hours = Math.floor(seconds / (60 * 60));
			out += String(hours).padStart(2, '0');
			out += ":";
			seconds -= hours * 60 * 60;
		}

		if (seconds >= 60) {
			const minutes = Math.floor(seconds / (60));
			out += String(minutes).padStart(2, '0');
			out += ":";
			seconds -= minutes * 60;
		}

		out += String(seconds).padStart(2, '0');

		return out;
	};

	useEffect(() => {
		const last_order_id = localStorage.getItem('last_order_id');
		if (last_order_id == null) {
			setDeadline(null);
			setLoaded(false);
			return;
		}

		const uid = localStorage.getItem("uid");
		const token = localStorage.getItem("token");
		fetch("https://chciuklizeno.cz/api/orders/deadline/" + uid + "/" + last_order_id + "?token=" + token)
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					setDeadline(data.deadline);
					setTime(() => {
						const now = new Date().getTime();
						const differenceInMilliseconds = data.deadline * 1000 - now;
						const seconds = Math.floor(differenceInMilliseconds / 1000);
						return secondsToTime(seconds);
					});
				}
				else {
					if (data.paid) {
						setPaid(true);
						props.hidePayButton();
					}
				}
				setLoaded(true);
			});

	}, []);

	useEffect(() => {
		const timer = setInterval(() => {
			setTime(() => {
				const now = new Date().getTime();
				const differenceInMilliseconds = deadline * 1000 - now;
				const seconds = Math.floor(differenceInMilliseconds / 1000);
				return secondsToTime(seconds);
			});
		}, 1000);
		return () => clearInterval(timer);
	}, [deadline]);

	return (
		<>
			{loaded ?
				<>
					{paid ?
						<Alert severity="success" style={{ marginTop: 40 }}>
							Objednávka byla zaplacena z kreditu. Můžete jít rovnou do <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { navigate("/profil") }}>profilu</span>.
						</Alert>
						:
						<>
							{expired ?
								<Alert severity="warning" style={{ marginTop: 40 }}>
									Čas na zaplacení vypršel.
								</Alert>
								:
								<Typography style={{ color: "#004d58", marginTop: 40, fontSize: 40 }}>
									{time}
								</Typography>
							}
						</>
					}
				</>
				:
				null
			}
		</>

	);
};

export default CountDown;