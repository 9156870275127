import React, { useEffect } from 'react';

const FetchInvoiceInfo = (props) => {
	useEffect(() => {
		const uid = localStorage.getItem("uid");
		const token = localStorage.getItem("token");
		const uidAndTokenGood = uid && token;
		if (uidAndTokenGood) {
			fetch("https://chciuklizeno.cz/api/users/" + uid + "?token=" + token)
				.then((response) => response.json())
				.then((data) => {
					const invoice_info = {
						ic: data.user.user_ic ?? "",
						dic: data.user.user_dic ?? "",
						name: data.user.user_invoice_name ?? "",
						street: data.user.user_street_and_number ?? "",
						streetNumber: " ",
						city: data.user.user_city ?? localStorage.getItem("city_name"),
						zip: data.user.user_zip ?? localStorage.getItem("zip"),
					};
					props.setInvoiceInfoFromApi(invoice_info);
				});
		}
	}, []);

	return null;
};

export default FetchInvoiceInfo;