import { Alert, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

function PlanningForm(props) {
	const [capacities, setCapacities] = useState([]);
	const [state, setState] = useState({
		chosenTime: props.state.chosenTime,
		day: props.state.day,
		weeks_loaded: false,
		capacities_loaded: false,
		week_index: props.state.week_index,
		week_chosen: false,
		clickedOnWeekIndex: props.state.clickedOnWeekIndex,
	});

	const weekDays = ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota", "Neděle",]
	const weekDaysShort = ["Ne", "Po", "Ut", "St", "Ct", "Pa", "So", "Ne",]

	useEffect(() => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			"duration": props.duration,
			"location": {
				"lat": 0,
				"lon": 0,
			},
			"place_id": JSON.parse(localStorage.getItem('availability'))?.zone_place_id ?? "NEVYPLNENO-PLANNING",
			"days": props?.weeks?.[props?.week_index]?.slice(0, 5),
			"region_id": JSON.parse(localStorage.getItem('availability'))?.region_id ?? null,
		});

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};

		fetch("https://chciuklizeno.cz/api/capacity/v4.5", requestOptions)
			.then(response => response.json())
			.then(result => {
				setCapacities(result);
			})
			.catch(error => console.log('error', error));
	}, [props.weeks, props.week_index]);

	const chooseDay = (e) => {
		const time = e.target.getAttribute("time");
		const day = e.target.getAttribute("day");
		const id = e.target.getAttribute("id");

		const dict = { Po: "v pondělí", Ut: "v úterý", St: "ve středu", Ct: "ve čtvrtek", Pa: "v pátek", So: "v sobotu", Ne: "v neděli", };
		const dict_rev = { Po: 0, Ut: 1, St: 2, Ct: 3, Pa: 4, So: 5, Ne: 6, };

		setState({
			chosenTime: time,
			day: dict[day],
			day_index: dict_rev[day],
			clickedOnWeekIndex: props.week_index,
			slot_id: id,
		});
		props.setClickedOnWeekIndex(props.week_index);
		//global state
		props.setChosenTime({
			chosenTime: time,
			day: dict[day],
			date: props.weeks_old[props.week_index][dict_rev[day]],
			slot_id: id,
		});
		e.stopPropagation();
	};

	const formatTime = (slot) => {
		return (slot.start + ":00")?.replace(".5:00", ":30") + '-' + (slot.end + ":00")?.replace(".5:00", ":30");
	};

	const isChosenSlot = (slot) => {
		return slot.id === props.state.slot_id;
	};

	const hasAtLeastOneCapacity = (capacities) => {
		let freeSlots = 0;
		for (let index = 0; index < capacities.length; index++) {
			const day_capacity = capacities[index];
			freeSlots += day_capacity.slots.length;
		}
		return freeSlots;
	};

	return (
		<div>
			<Typography variant="h3" style={{ marginBottom: 20 }}>
				Zvolte si časové okno
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 20 }}>
				Váš úklid bude trvat {props.duration} hodiny.
			</Typography>
			<Alert severity={hasAtLeastOneCapacity(capacities) > 0 ? "success" : "warning"} style={{ marginBottom: 20 }}>
				{hasAtLeastOneCapacity(capacities) > 0 ? "Skvělé zprávy! Tento týden máme volnou kapacitu." : "Je nám to líto, pro tento týden již nemáme volné kapacity. Zkuste jiný termín, nebo nás kontaktujte skrze chat v pravém dolním rohu a my se Vám pokusíme vyhovět."}
			</Alert>
			<Grid container spacing={2}>
				{capacities.map((day_capacity, index) =>
					<Grid item style={{ marginBottom: 30, width: props.mobile ? "100%" : "20%" }} key={index}>
						<Typography variant={props.mobile ? "h3" : "body1"} style={{ marginBottom: 12 }}>
							{weekDays[new Date(day_capacity.date).getDay()]} ({new Date(day_capacity.date).toLocaleDateString('cs-CZ', { day: 'numeric', month: 'numeric' })})
						</Typography>
						{day_capacity.slots.length <= 0 ?
							<div style={{ display: "inline-block", width: "100%" }}>
								<div style={{ display: "inline-block", width: "100%" }}>
									<div className="text-center"
										style={{
											backgroundColor: "#FF4747",
											padding: "2px 0",
											margin: "2px 0",
											borderRadius: "4px",
										}}>
										<Typography variant="body1" className="drop-shadow" style={{ color: "#fff" }}>
											Není kapacita
										</Typography>
									</div>
								</div>
							</div>
							:
							<div style={{
								columnCount: props.mobile ? 2 : 1,
								columnGap: "10px"
							}}>
								{day_capacity.slots.map((slot, jndex) =>
									<div style={{ display: "inline-block", width: "100%" }} key={jndex}>
										<div
											className="text-center cursor-pointer text-white"
											style={{
												backgroundColor: isChosenSlot(slot, day_capacity) ? "#FFAF24" : "#458F00",
												padding: "2px 0",
												margin: "2px 0",
												borderRadius: "4px",
											}}
											onClick={chooseDay}
											time={formatTime(slot)}
											day={weekDaysShort[new Date(day_capacity.date).getDay()]}
											id={slot.id}
										>
											<Typography variant="body1"
												className="drop-shadow"
												time={formatTime(slot)}
												day={weekDaysShort[new Date(day_capacity.date).getDay()]}
												id={slot.id}
												style={{ color: "#fff" }}
											>
												{formatTime(slot)}
											</Typography>
										</div>
									</div>
								)}
							</div>
						}
					</Grid>
				)}
			</Grid>
		</div>
	);
}

export default PlanningForm;