import React, { useState } from 'react';
import { useParams } from 'react-router';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Button, CircularProgress, createTheme, Grid, ThemeProvider, Typography } from '@mui/material';
import BackButton from '../BackButton';
import jsPDF from 'jspdf';
import voucher_1 from '../assets/images/templates/voucher_1.png';
import voucher_2 from '../assets/images/templates/voucher_2.png';
import voucher_3 from '../assets/images/templates/voucher_3.png';
import voucher_4 from '../assets/images/templates/voucher_4.png';
import voucher_5 from '../assets/images/templates/voucher_5.png';
import '../assets/fonts/Agrandir-Regular.js';
import DownloadIcon from '@mui/icons-material/Download';
import { useEffect } from 'react';
import { saveAs } from 'file-saver';

const images = [
	voucher_1,
	voucher_2,
	voucher_3,
	voucher_4,
	voucher_5,
];

const VouchersGenerator = () => {
	const { code } = useParams();
	const [amount, setAmount] = useState(0);
	const [expiration, setExpiration] = useState('');
	const [variant, setVariant] = useState(0);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		fetch('https://chciuklizeno.cz/api/voucher_amount?coupon_code=' + code)
			.then(response => {
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				return response.json();
			})
			.then(data => {
				if (data.status === 'ok') {
					setAmount(data.amount);
					setExpiration(data.expiration);
					setLoaded(true);
				} else {
					console.error('Error:', data.message);
				}
			})
			.catch(error => {
				console.error('Error fetching data:', error);
			});
	}, []);

	const downloadPDF = () => {
		const doc = new jsPDF({
			orientation: "portrait",
			unit: "mm",
			format: 'a4',
		});

		//const date = new Intl.DateTimeFormat('cs-CZ', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(new Date() + 365 * 24 * 60 * 60 * 1000));
		const date = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toLocaleDateString('cs-CZ', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('.').join('-');

		const scale = 7.7;
		const fontScale = 3.5;

		// image
		const imgWidth = 1600 / scale;
		const imgHeight = 750 / scale;
		let image = images[variant] ?? voucher_1;
		doc.addImage(image, "PNG", 0, 0, imgWidth, imgHeight);

		// font
		doc.setFont('Agrandir-Regular');
		doc.setTextColor(0, 77, 88);

		// texts
		doc.setFontSize(36 / fontScale);
		doc.text("Částka:", 1180 / scale, 412 / scale);
		doc.setFontSize(64 / fontScale);
		doc.text(amount + " Kč", 1320 / scale, 412 / scale);
		doc.setFontSize(36 / fontScale);
		doc.text("Kód:", 1180 / scale, 480 / scale);
		doc.setFontSize(40 / fontScale);
		doc.text(code.toUpperCase(), 1250 / scale, 480 / scale);
		doc.setFontSize(36 / fontScale);
		doc.text("Voucher je platný do " + expiration, 1130 / scale, 670 / scale);
		doc.save("youklid-voucher-" + code.toUpperCase() +".pdf");

		// var file = new File(["Hello, world!"], "hello world.txt", { type: "text/plain;charset=utf-8" });
		// saveAs(file);

		// const pdfData = doc.output('blob');
		// const blob = new Blob([pdfData], { type: 'application/pdf' });
		// //const url = URL.createObjectURL(blob);

		// // const blob = new Blob([blobData], { type: 'application/octet-stream' });

		// // Save Blob as a file
		// saveAs(blob, 'voucher.pdf');


		// // window.location = url;

		// // const link = document.createElement('a');
		// // link.href = url;
		// // link.download = 'voucher.pdf';
		// // link.click();

	};


	return (
		<ContentWrapper showCTA={false} maxWidth={"md"}>
			<Typography variant="h1" style={{ marginBottom: 20 }}>
				Vyrobte si PDF poukázku
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 30 }}>
				Teď už jen stačí vybrat k poukázce grafiku.
			</Typography>
			<ThemeProvider theme={createTheme({
				palette: {
					primary: {
						main: '#8c5cbc',
					},
				},
				shape: {
					borderRadius: 100,
				},
			})}>
				{!loaded && <CircularProgress />}
				{loaded && <div>
					<Grid container spacing={4} justifyContent={'center'}>
						{images.map((image, index) => (
							<Grid item xs={12} md={4}>
								<img
									alt=''
									src={image}
									onClick={() => setVariant(index)}
									style={{
										border: variant === index ? '3px solid #8c5cbc' : '3px solid white',
										borderRadius: 10,
									}}
								/>
							</Grid>
						))}
					</Grid>
					<div style={{ height: 50 }} />
					<Grid container justifyContent={'center'}>
						<Button
							onClick={downloadPDF}
							variant="contained"
							endIcon={<DownloadIcon />}
							size={'large'}
							style={{ boxShadow: 'none' }}
						>
							Stáhnout poukázku
						</Button>
					</Grid>
				</div>}
			</ThemeProvider>
		</ContentWrapper>
	);
};

export default VouchersGenerator;