import { Button, CircularProgress, Grid, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LayoutCard from './Components/LayoutCard';
import { useNavigate } from 'react-router';
import useIsMobile from './lib/Responsive';

const Balance = (props) => {
	const navigate = useNavigate();
	const isMobile = useIsMobile();
	const [state, setState] = useState({
		balance: 0,
		loaded: false,
		next_is_paid: false,
		paid_till: null,
		loaded_suff: false,
	});

	useEffect(() => {
		const uid = localStorage.getItem("uid");
		const token = localStorage.getItem("token");
		// balance
		fetch("https://chciuklizeno.cz/api/users/" + uid + "/balance?token=" + token, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					setState(state => ({ ...state, balance: data.balance, loaded: true }));
					if (props.setParentBalance != null)
						props.setParentBalance(data.balance);
				}
				else {
					setState(state => ({ ...state, balance: 0, loaded: true }));
					if (props.setParentBalance != null)
						props.setParentBalance(0);
				}
			})
			.catch(e => {
				setState(state => ({ ...state, balance: 0, loaded: true }));
				if (props.setParentBalance != null)
					props.setParentBalance(0);
			});
	}, []);

	useEffect(() => {
		const uid = localStorage.getItem("uid");
		const token = localStorage.getItem("token");
		// credit sufficiency
		fetch("https://chciuklizeno.cz/api/users/" + uid + "/credit_sufficiency?token=" + token, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					setState(state => ({
						...state,
						next_is_paid: data.paid,
						paid_till: data.paid_till,
						loaded_suff: true,
					}));
				}
				else {
					setState(state => ({
						...state,
						next_is_paid: false,
						paid_till: null,
						loaded_suff: true,
					}));
				}
			})
			.catch(e => {
				setState(state => ({ ...state, balance: 0, loaded: true }));
			});
	}, []);

	return (
		<LayoutCard>
			<Grid container justifyContent={'space-between'} alignItems={'center'} style={{
				//borderBottom: "1px solid rgba(0, 77, 88, 0.2)"
			}}>
				<Grid item>
					<Typography variant="h3" gutterBottom>
						{props.heading ?? 'Peněženka'}
					</Typography>
				</Grid>
				<Grid item>
					<Grid container alignItems={'center'} gap={isMobile ? 2 : 4}>
						{state.loaded ?
							<Typography variant="h3">
								{state.balance} Kč
							</Typography>
							:
							<CircularProgress size="20px" style={{ color: "#004D58", marginRight: 50 }} />
						}
						{props.displayLink &&
							<Button
								style={{
									whiteSpace: "nowrap",
									borderRadius: 19,
									boxShadow: "none",
									background: "#004D58",
									textTransform: "initial",
									fontFamily: "agrandir",
								}}
								variant="contained"
								onClick={() => {
									navigate("/kredit");
								}}
							>
								<span style={{ position: "relative", top: 2 }}>
									{isMobile ? 'Dobití' : 'Dobití a historie'}
								</span>
							</Button>
						}
					</Grid>
				</Grid>
			</Grid>
			<div style={{ height: 12 }} />
			{state.loaded_suff ?
				<Typography variant="body1" component="div">
					{
						state.paid_till != null ?
							<>Poslední zaplacená objednávka je {state.paid_till}</> :
							null
					}
					<div style={{ height: 4 }} />
					{
						state.next_is_paid ?
							"Vaše další objednávka je zaplacená." :
							"Nemáte zaplacenou další objednávku."
					}
				</Typography>
				:
				<Skeleton width={300} height={28} />
			}
		</LayoutCard>
	);
};

export default Balance;