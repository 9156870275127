import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, Skeleton, Slider, Typography } from "@mui/material";
import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns";
import MainTheme from "./Wrapper/MainTheme";
import PlanningForm from "./PlanningForm";
import { isMobile } from "react-device-detect";

function tomorrow() {
  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow;
}

function nextDay(day) {
  let nextDay = new Date(day);
  nextDay.setDate(day.getDate() + 1);
  return nextDay;
}

class CapacitiesNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosenTime: null,
      day: null,
      weeks_loaded: false,
      capacities_loaded: false,
      week_index: null,
      week_chosen: false,
      clickedOnWeekIndex: null,
      slider_duration: 3,
    };
  }

  componentDidMount() {
    const apiCallURL = "https://chciuklizeno.cz/api/weeks";
    fetch(apiCallURL, {
      method: "GET",
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== "ok") {
          this.setState(() => ({
            message: "Problém s připojením.",
          }));
          return;
        }
        this.setState(() => ({
          weeks: data.weeks,
          week_chosen: false,
          week_index: -1,
          weeks_loaded: true,
        }));
      });

    const apiCallURL_new = "https://chciuklizeno.cz/api/weeks/v2";
    fetch(apiCallURL_new, {
      method: "GET",
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== "ok") {
          this.setState(() => ({
            message: "Problém s připojením.",
          }));
          return;
        }
        this.setState(() => ({
          weeks_new: data.weeks,
          weeks_loaded_new: true,
        }));
      });

  }
  
  chooseWeek = (event) => {
    let index = event.target.getAttribute("week");
    this.setState({
      week_chosen: true,
      week_index: index,
      capacities_loaded: false,
    });
    if (this.state.capacity == null) {
      this.setState({
        capacity: {
          Po: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          Ut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          St: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          Ct: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          Pa: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      });
    }

    const apiCallURL =
      "https://chciuklizeno.cz/api/week_capacity/v3/" +
      this.state.weeks[index][0].replace(/([0-9]*).([0-9]*). ([0-9]*)/gi, (str, day, month, year) => {
        return year + "-" + String(month).padStart(2, "0") + "-" + String(day).padStart(2, "0");
      });
    fetch(apiCallURL, {
      method: "GET",
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== "ok") {
          this.setState(() => ({
            message: "Problém s připojením.",
          }));
          return;
        }
        this.setState(() => ({
          capacity: data.capacity,
          capacities_loaded: true,
        }));
      });
  };

  render() {
    const chooseWeek = this.chooseWeek;
    const local_state = this.state;

    const props_fake = {
      chosenTime: this.state.chosenTime,
      day: null,
      weeks_loaded: true,
      capacities_loaded: true,
      week_index: this.state.week_index,
      week_chosen: true,
      clickedOnWeekIndex: true,
    };

    return (
      <div className="m-16">
        <div>
          {this.state.weeks_loaded ? (
            <Grid container>
              {this.state.weeks?.map((week, week_index) => {
                return (
                  <Grid item key={week[0].split(" ")[0]}>
                    <Button onClick={chooseWeek} style={{ margin: "8px", fontFamily: "graphik_regular, Arial" }} week={week_index} variant={week_index == this.state.week_index ? "contained" : "outlined"}>
                      {week[0].split(" ")[0]} - {week[6].split(" ")[0]}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Grid container>
              {[1, 2, 3, 4, 5, 6, 7, 8].map((week, week_index) => (
                <Grid item key={week}>
                  <Skeleton width={100} height={40} sx={{ margin: "0 10px" }} />
                </Grid>
              ))}
            </Grid>
          )}
        </div>

        <div style={{ height: 40 }} />

        <MainTheme>

          <Slider step={0.5} min={3} max={11} onChange={(e) => {
            this.setState({
              slider_duration: e.target.value,
            });
          }} />

          {this.state.slider_duration}h

          <Button onClick={() => {
            localStorage.setItem('availability', JSON.stringify({ zone_place_id: "ChIJP7q7Wj6lC0cRMLoUZg-vAAQ" }));
          }}>Středočeský kraj</Button>
          <Button onClick={() => {
            localStorage.setItem('availability', JSON.stringify({ zone_place_id: "ChIJi3lwCZyTC0cRkEAWZg-vAAQ" }));
          }}>Praha</Button>

          <PlanningForm
            duration={this.state.slider_duration}
            weeks={local_state.weeks_new}
            weeks_old={local_state.weeks}
            week_index={local_state.week_index}
            setClickedOnWeekIndex={() => { }}
            setChosenTime={() => { }}
            state={props_fake}
            mobile={isMobile}
          />
        </MainTheme>
      </div>
    );
  }
}

export default CapacitiesNew;
