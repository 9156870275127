import { Alert, Button, CircularProgress, Collapse, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import React, { useState } from 'react';

const theme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
		secondary: {
			main: '#8c5cbc', // Secondary accent color
		},
	},
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: '#8c5cbc', // Apply primary color when focused
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					'&.MuiButton-containedPrimary': {
						backgroundColor: '#8c5cbc',
					},
					'&.MuiButton-containedSecondary': {
						backgroundColor: '#8c5cbc',
					},
				},
			},
		},
	},
});

const TicketForm = (props) => {
	const category_code = props.category_code;
	const text_template = props.text_template;
	const success_alert = props.success_alert;
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [text, setText] = useState(text_template);

	const [showForm, setShowForm] = useState(true);
	const [sent, setSent] = useState(false);
	const [response, setResponse] = useState(false);
	const [success, setSuccess] = useState(null);

	const post_ticket = () => {
		setSent(true);
		fetch('https://chciuklizeno.cz/api/ticket', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name: name,
				email: email,
				phone: phone,
				text: text,
				category_code: category_code,
			})
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok ' + response.statusText);
				}
				return response.json();
			})
			.then(data => {
				if (data.status == 'ok') {
					setSuccess(true);
					setName('');
					setPhone('');
					setEmail('');
					setText(props.text_template);
				}
				else {
					throw new Error(data.message);
				}
				setResponse(true);
			})
			.catch(error => {
				setSuccess(false);
				setResponse(true);
			});
	};

	return (
		<>
			<div style={{ height: 20 }} />
			{/* <Collapse in={!showForm}>
				<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
					<Alert severity='info' style={{ borderRadius: 100, margin: '20px 0' }}>
						Neodpověděli jsme vám na vaší otázku, nebo chcete vyplnit požadavek?
					</Alert>
					<Button
						onClick={() => setShowForm(true)}
						variant="outline"
						style={{
							boxShadow: "none",
							color: "#ffffff",
							backgroundColor: "#8c5cbc",
							borderColor: "#8c5cbc",
							fontSize: 16,
							fontFamily: 'agrandir',
							textTransform: 'initial',
							borderRadius: 100,
							marginBottom: 20,
						}}
					>
						Napište nám
					</Button>
				</div>
			</Collapse> */}
			{/* <Collapse in={showForm}> */}
			<ThemeProvider theme={theme}>
				{success == null && <>
					<TextField
						fullWidth
						margin="normal"
						label="Jméno a příjmení"
						variant="outlined"
						value={name}
						onChange={(e) => setName(e.target.value)}
						size='small'
						sx={{ '& .MuiOutlinedInput-root': { borderRadius: 100 }, }}
					/>
					<TextField
						fullWidth
						margin="normal"
						label="Email"
						variant="outlined"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						size='small'
						sx={{ '& .MuiOutlinedInput-root': { borderRadius: 100 }, }}
					/>
					<TextField
						fullWidth
						margin="normal"
						label="Telefon (nepovinné)"
						variant="outlined"
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
						size='small'
						sx={{ '& .MuiOutlinedInput-root': { borderRadius: 100 }, }}
					/>
					<TextField
						fullWidth
						margin="normal"
						label="Zpráva"
						variant="outlined"
						multiline
						rows={4}
						value={text}
						onChange={(e) => setText(e.target.value)}
						size='small'
						sx={{ '& .MuiOutlinedInput-root': { borderRadius: '20px' }, }}
					/>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						{(!sent && !response) ?
							<Button
								variant="contained"
								size='large'
								onClick={post_ticket}
								style={{
									boxShadow: "none",
									backgroundColor: "#8c5cbc",
									borderColor: "#8c5cbc",
									fontFamily: 'agrandir',
									textTransform: 'initial',
									borderRadius: 100,
									marginBottom: 20,
									height: 43,
								}}
							>
								<span style={{ position: 'relative', top: 2 }}>Odeslat požadavek</span>
							</Button>
							:
							<Button
								variant="contained"
								size='large'
								style={{
									height: 43,
									backgroundColor: "#8c5cbc",
									borderColor: "#8c5cbc",
									borderRadius: "400px",
									fontFamily: "agrandir",
									textTransform: "initial",
									boxShadow: "none",
								}}
								disabled
							>
								<CircularProgress size="24px" style={{ color: "#fff" }} />
							</Button>
						}
					</div>
				</>}
				{success &&
					<Alert severity={'success'} style={{ marginTop: 20 }}>
						{success_alert ?? 'Výborně, váš požadavek nyní evidujeme a co nejdříve ho vyřešíme.'}
					</Alert>
				}
				{success == false &&
					<Alert severity={'warning'} style={{ marginTop: 20 }}>
						Omlouváme se, při odesílání požadavku se stala chyba. Napište nám prosím na <a style={{ textDecoration: 'underline' }} href="mailto:info@youklid.cz">info@youklid.cz</a>.
					</Alert>
				}
			</ThemeProvider>
			{/* </Collapse> */}
		</>
	);
};

export default TicketForm;