import { Alert, Badge, Button, Checkbox, Chip, CircularProgress, Collapse, FormControlLabel, Grid, IconButton, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay, TimePicker, csCZ } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/cs';
import CloseIcon from '@mui/icons-material/Close';
import { cleanDigitSectionValue } from '@mui/x-date-pickers/internals/hooks/useField/useField.utils';


const theme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
	},
	typography: {
		fontFamily: 'agrandir',
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
				},
			},
		},
	},
});

function OrderDelete(props) {
	const [alert, setAlert] = useState({
		message: null,
		severity: null,
	});

	return (
		<>
			<Typography variant='h3' style={{ marginBottom: 30 }}>Zrušit úklid</Typography>
			<Collapse in={alert.message != null}>
				<Alert
					severity={alert.severity ?? 'success'}
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setAlert({
									message: null,
								});
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					style={{ margin: '0 0 20px 0' }}
				>
					{alert.message}
				</Alert>
			</Collapse>
			<div>
				<Typography variant='body1' style={{ marginBottom: 10 }}>
					Pokud to není nezbytně nutné, moc Vás prosíme o nerušení úklidů na poslední chvíli. Držíme pro Vás kapacitu našich hospodyň, které s úklidem dopředu počítají a při zrušení během 48-72 hodin před začátkem se výrazně snižuje šance na opětovné naplnění této kapacity.
				</Typography>
				<Typography variant='body1' style={{ marginBottom: 10 }}>
					Moc děkujeme a ještě před potvrzením prosíme o vyplnění dotazníku.
				</Typography>
				<StornoForm order_id={props.order.order_id} reload={props.reload} />
			</div>
		</>
	);
}

const StornoForm = (props) => {
	const [answer, setAnswer] = useState(null);
	const [otherText, setOtherText] = useState('');

	const post_storno = () => {
		const uid = localStorage.getItem("uid");
		const token = localStorage.getItem("token");
		const order_id = props.order_id;
		const reason = answer == 'other' ? 'other:' + otherText : answer;
		fetch("https://chciuklizeno.cz/api/order_change/" + uid + "/storno",
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json', },
				body: JSON.stringify({
					"token": token,
					"order_id": order_id,
					"reason": reason,
				}),
			})
			.then(response => response.json())
			.then(data => {
				console.log(data);
				props.reload();
			})
			.catch(error => console.error('error', error));
	};

	return (
		<ThemeProvider theme={theme}>
			{/* {answer == 'other' ? 'other:' + otherText : answer} */}
			<Typography variant='body1' style={{ marginBottom: 10 }}>
				Ruším úklid, protože:
			</Typography>
			<Grid container direction={'column'} gap={1}>
				<Button onClick={() => setAnswer('unfit')} variant={answer == 'unfit' ? 'contained' : 'outlined'}>
					Služba mi nevyhovuje
				</Button>
				<Button onClick={() => setAnswer('bad_time')} variant={answer == 'bad_time' ? 'contained' : 'outlined'}>
					Termín mi nevyhovuje
				</Button>
				{answer == 'bad_time' &&
					<Alert severity='info' style={{ margin: '10px 0' }}>
						Tip: Víte že, můžete termín úklidu sami změnit pod kategorií "Změna termínu úklidu"?
					</Alert>
				}
				<Button onClick={() => setAnswer('vacation')} variant={answer == 'vacation' ? 'contained' : 'outlined'}>
					Změnil se mi plán
				</Button>
				<Button onClick={() => setAnswer('illness')} variant={answer == 'illness' ? 'contained' : 'outlined'}>
					Nemoc
				</Button>
				<Button onClick={() => setAnswer('other')} variant={answer == 'other' ? 'contained' : 'outlined'}>
					Jiné důvody
				</Button>
				{answer == 'other' &&
					<TextField value={otherText} onChange={(e) => setOtherText(e.target.value)} />
				}
			</Grid>
			<Grid container justifyContent={'flex-end'} gap={1}>
				<Button onClick={post_storno} variant='contained' style={{ marginTop: 20 }}>
					Zrušit úklid a vrátit peníze
				</Button>
			</Grid>
		</ThemeProvider>
	);

};

export default OrderDelete;