import React, { useState } from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Alert, Autocomplete, Button, Grid, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import BackButton from '../BackButton';
import PricingExamples from '../PricingExamples';
import useIsMobile, { Default } from '../lib/Responsive';
import CallToAction from '../Wrapper/CallToAction';
import { useNavigate } from 'react-router';


const ExpansionVoting = () => {
	const isMobile = useIsMobile();
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [city, setCity] = useState('');
	const [administrativeAreaLevel2, setAdministrativeAreaLevel2] = useState('');
	const [homeSize, setHomeSize] = useState('');

	const [voted, setVoted] = useState(false);

	const send = async () => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				voter_email: email,
				vote_topic: 'zone opening',
				vote_choice: administrativeAreaLevel2,
				vote_comment: city + ';' + homeSize,
			}),
		};

		fetch("https://chciuklizeno.cz/api/vote", requestOptions)
			.then((response) => response.json())
			.then((data) => {
				setVoted(true);
			})
			.catch((error) => console.error(error));
	};

	return (
		<>
			<ContentWrapper showCTA={false} maxWidth={"lg"} disable_theme={true}>
				<ThemeProvider theme={isMobile ? mobileTheme : desktopTheme}>
					<BackButton />
					<Grid container justifyContent={'space-between'}>
						{!voted ?
							<Grid item xs={12}>
								<Typography variant="h3" style={{ marginBottom: 20 }}>
									Hlasujte pro expanzi k vám domů
								</Typography>
								<Typography variant="body1" style={{ marginBottom: 40 }}>
									Všechny hlasy počítáme a při rozhodování kam zamíříme dál koukáme hlavně na ně.
								</Typography>
								<div style={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr auto 1fr', gap: 16 }}>
									<TextField
										color="secondary"
										style={{
											backgroundColor: "#FBFDFC",
											borderRadius: "6px",
											width: '100%',
										}}
										label={'Město / Obec'}
										value={city}
										onChange={(e) => { setCity(e.target.value) }}
									/>
									<Autocomplete
										disablePortal
										options={options.sort((a, b) => -b.l1.localeCompare(a.l1))}
										groupBy={(option) => option.l1}
										getOptionLabel={(option) => option.l2}
										renderInput={(params) => <TextField {...params} label="Okres" />}
										onChange={(e, option) => setAdministrativeAreaLevel2(option.l2)}
										noOptionsText={'Vyberte jeden z okresů'}
									/>
									<div style={{ display: 'flex', justifyContent: 'center', gap: 16 }}>
										<Button
											onClick={() => setHomeSize('flat')}
											style={{
												backgroundColor: homeSize == 'flat' ? '#004d58' : "transparent",
												color: homeSize == 'flat' ? '#ffffff' : "#004d58",
												border: '1px solid #004d58',
												borderRadius: "400px",
												fontFamily: "agrandir",
												textTransform: "initial",
												fontSize: 16,
												padding: "4px 16px",
												boxShadow: "none",
												width: '100%',
											}}
											variant="contained"
										>
											<span style={{ position: "relative", top: "2px" }}>
												Bydlím v bytě
											</span>
										</Button>
										<Button
											onClick={() => setHomeSize('house')}
											style={{
												backgroundColor: homeSize == 'house' ? '#004d58' : "transparent",
												color: homeSize == 'house' ? '#ffffff' : "#004d58",
												border: '1px solid #004d58',
												borderRadius: "400px",
												fontFamily: "agrandir",
												textTransform: "initial",
												fontSize: 16,
												padding: "4px 16px",
												boxShadow: "none",
												width: '100%',
											}}
											variant="contained"
										>
											<span style={{ position: "relative", top: "2px" }}>
												Bydlím v domě
											</span>
										</Button>
									</div>
									<TextField
										color="secondary"
										style={{
											backgroundColor: "#FBFDFC",
											borderRadius: "6px",
											width: '100%',
										}}
										label={'Email'}
										value={email}
										onChange={(e) => { setEmail(e.target.value) }}
										helperText={'Email vyžadujeme abychom zaručili, že nehlasujete vícekrát.'}
									/>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<Button
											onClick={send}
											style={{
												backgroundColor: "#8C5CBC",
												borderColor: "#8C5CBC",
												borderRadius: "400px",
												fontFamily: "agrandir",
												textTransform: "initial",
												fontSize: 16,
												padding: "4px 16px",
												boxShadow: "none",
												width: '100%',
											}}
											variant="contained"
										>
											<span style={{ position: "relative", top: "2px" }}>
												Hlasovat o expanzi ke mně domů
											</span>
										</Button>
									</div>
								</div>
							</Grid>
							:
							<Alert severity='success'>
								Děkujeme za váš hlas. Až budeme uklízet ve vaší obci, tak se vám ozveme jako první.
							</Alert>
						}
					</Grid>
				</ThemeProvider>
			</ContentWrapper >
		</>
	);
};

const options = [
	{ l1: "Středočeský kraj", l2: "okres Beroun" },
	{ l1: "Středočeský kraj", l2: "okres Praha-východ" },
	{ l1: "Jihomoravský kraj", l2: "okres Brno-město" },
	{ l1: "Plzeňský kraj", l2: "okres Plzeň-město" },
	{ l1: "Středočeský kraj", l2: "okres Praha-západ" },
	{ l1: "Jihomoravský kraj", l2: "okres Brno-venkov" },
	{ l1: "Středočeský kraj", l2: "okres Kolín" },
	{ l1: "Středočeský kraj", l2: "okres Mělník" },
	{ l1: "Ústecký kraj", l2: "okres Litoměřice" },
	{ l1: "Moravskoslezský kraj", l2: "okres Frýdek-Místek" },
	{ l1: "Středočeský kraj", l2: "okres Kladno" },
	{ l1: "Středočeský kraj", l2: "okres Příbram" },
	{ l1: "Královéhradecký kraj", l2: "okres Hradec Králové" },
	{ l1: "Středočeský kraj", l2: "okres Benešov" },
	{ l1: "Středočeský kraj", l2: "okres Nymburk" },
	{ l1: "Zlínský kraj", l2: "okres Zlín" },
	{ l1: "Královéhradecký kraj", l2: "okres Jičín" },
	{ l1: "Královéhradecký kraj", l2: "okres Trutnov" },
	{ l1: "Středočeský kraj", l2: "okres Mladá Boleslav" },
	{ l1: "Zlínský kraj", l2: "okres Vsetín" },
	{ l1: "Liberecký kraj", l2: "okres Jablonec nad Nisou" },
	{ l1: "Kraj Vysočina", l2: "okres Havlíčkův Brod" },
	{ l1: "Kraj Vysočina", l2: "okres Pelhřimov" },
	{ l1: "Středočeský kraj", l2: "okres Rakovník" },
	{ l1: "Středočeský kraj", l2: "okres Kutná Hora" },
	{ l1: "Liberecký kraj", l2: "okres Liberec" },
	{ l1: "Ústecký kraj", l2: "okres Ústí nad Labem" },
	{ l1: "Ústecký kraj", l2: "okres Louny" },
	{ l1: "Královéhradecký kraj", l2: "okres Náchod" },
	{ l1: "Jihočeský kraj", l2: "okres Tábor" },
	{ l1: "Plzeňský kraj", l2: "okres Rokycany" },
	{ l1: "Kraj Vysočina", l2: "okres Třebíč" },
	{ l1: "Jihomoravský kraj", l2: "okres Vyškov" },
	{ l1: "Liberecký kraj", l2: "okres Semily" },
	{ l1: "Liberecký kraj", l2: "okres Česká Lípa" },
	{ l1: "Jihomoravský kraj", l2: "okres Břeclav" },
	{ l1: "Plzeňský kraj", l2: "okres Plzeň-sever" },
	{ l1: "Moravskoslezský kraj", l2: "okres Ostrava-město" },
	{ l1: "Olomoucký kraj", l2: "okres Jeseník" },
	{ l1: "Kraj Vysočina", l2: "okres Žďár nad Sázavou" },
	{ l1: "Pardubický kraj", l2: "okres Pardubice" },
	{ l1: "Zlínský kraj", l2: "okres Uherské Hradiště" },
	{ l1: "Jihočeský kraj", l2: "okres České Budějovice" },
	{ l1: "Jihočeský kraj", l2: "okres Prachatice" },
	{ l1: "Plzeňský kraj", l2: "okres Domažlice" },
	{ l1: "Jihomoravský kraj", l2: "okres Znojmo" },
	{ l1: "Jihočeský kraj", l2: "okres Český Krumlov" },
	{ l1: "Karlovarský kraj", l2: "okres Karlovy Vary" },
	{ l1: "Olomoucký kraj", l2: "okres Olomouc" },
	{ l1: "Pardubický kraj", l2: "okres Chrudim" },
	{ l1: "Moravskoslezský kraj", l2: "okres Nový Jičín" },
	{ l1: "Jihočeský kraj", l2: "okres Jindřichův Hradec" },
	{ l1: "Ústecký kraj", l2: "okres Chomutov" },
	{ l1: "Karlovarský kraj", l2: "okres Sokolov" },
	{ l1: "Jihomoravský kraj", l2: "okres Blansko" },
	{ l1: "Kraj Vysočina", l2: "okres Jihlava" },
	{ l1: "Plzeňský kraj", l2: "okres Plzeň-jih" },
	{ l1: "Pardubický kraj", l2: "okres Ústí nad Orlicí" },
	{ l1: "Jihočeský kraj", l2: "okres Písek" },
	{ l1: "Zlínský kraj", l2: "okres Kroměříž" },
	{ l1: "Moravskoslezský kraj", l2: "okres Karviná" },
	{ l1: "Pardubický kraj", l2: "okres Svitavy" },
	{ l1: "Ústecký kraj", l2: "okres Teplice" },
	{ l1: "Plzeňský kraj", l2: "okres Tachov" },
	{ l1: "Ústecký kraj", l2: "okres Most" },
	{ l1: "Olomoucký kraj", l2: "okres Prostějov" },
	{ l1: "Královéhradecký kraj", l2: "okres Rychnov nad Kněžnou" },
	{ l1: "Moravskoslezský kraj", l2: "okres Opava" },
	{ l1: "Olomoucký kraj", l2: "okres Přerov" },
	{ l1: "Olomoucký kraj", l2: "okres Šumperk" },
	{ l1: "Jihočeský kraj", l2: "okres Strakonice" },
	{ l1: "Moravskoslezský kraj", l2: "okres Bruntál" },
	{ l1: "Jihomoravský kraj", l2: "okres Hodonín" },
	{ l1: "Plzeňský kraj", l2: "okres Klatovy" },
	{ l1: "Karlovarský kraj", l2: "okres Cheb" },
	{ l1: "Ústecký kraj", l2: "okres Děčín" },
];

const desktopTheme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
		secondary: {
			main: '#004D58',
		},
	},
	typography: {
		display: "block",
		fontFamily: "agrandir, Arial, Arial",
		fontSize: 16,
		color: "#004D58",
		body0: {
			fontFamily: "agrandir, Arial, Arial",
			fontSize: 20,
			color: "#004D58",
		},
		body1: {
			fontFamily: "agrandir, Arial, Arial",
			fontSize: 16,
			color: "#004D58",
		},
		body2: {
			fontFamily: "agrandir, Arial, Arial",
			fontSize: 14,
			color: "#004D58",
		},
		h1: {
			fontFamily: "agrandir, Arial, Arial",
			fontSize: 64,
			color: "#004D58",
			textAlign: "left",
		},
		h2: {
			fontFamily: "agrandir, Arial, Arial",
			fontSize: 48,
			color: "#004D58",
			textAlign: "left",
		},
		h3: {
			fontFamily: "agrandir, Arial, Arial",
			fontSize: 24,
			color: "#004D58",
			textAlign: "left",
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
				},
			},
		},
	},
});

const mobileTheme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
		secondary: {
			main: '#004D58',
		},
	},
	typography: {
		display: "block",
		fontFamily: "agrandir, Arial, Arial",
		fontSize: 14,
		color: "#004D58",
		body0: {
			fontFamily: "agrandir, Arial, Arial",
			fontSize: 18,
			color: "#004D58",
		},
		body1: {
			fontFamily: "agrandir, Arial, Arial",
			fontSize: 14,
			color: "#004D58",
		},
		body2: {
			fontFamily: "agrandir, Arial, Arial",
			fontSize: 12,
			color: "#004D58",
		},
		h1: {
			fontFamily: "agrandir, Arial, Arial",
			fontSize: 36,
			color: "#004D58",
			textAlign: "center",
		},
		h2: {
			fontFamily: "agrandir, Arial, Arial",
			fontSize: 28,
			color: "#004D58",
			textAlign: "center",
		},
		h3: {
			fontFamily: "agrandir, Arial, Arial",
			fontSize: 18,
			color: "#004D58",
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
				},
			},
		},
	},
});

export default ExpansionVoting;