import React from 'react';
import ContentWrapper from './Wrapper/ContentWrapper';
import { Grid, Typography } from '@mui/material';
import useIsMobile from './lib/Responsive';

function RecruitmentThankYouPage(props) {
	const isMobile = useIsMobile();

	return (
		<ContentWrapper maxWidth={'md'}>
			<Typography variant='h1' style={{ textAlign: 'center' }}>
				Děkujeme za váš zájem{!isMobile && <br />} o práci v Youklidu!
			</Typography>
			<Grid container justifyContent={'center'} style={{ margin: '60px 0' }}>
				<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clock-24" width="100" height="100" viewBox="0 0 24 24" stroke-width="1" stroke="#004d58" fill="none" stroke-linecap="round" stroke-linejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" fill="none" />
					<path d="M3 12a9 9 0 0 0 5.998 8.485m12.002 -8.485a9 9 0 1 0 -18 0" />
					<path d="M12 7v5" />
					<path d="M12 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2" />
					<path d="M18 15v2a1 1 0 0 0 1 1h1" />
					<path d="M21 15v6" />
				</svg>
			</Grid>
			<Typography variant='h3' style={{ textAlign: 'center' }}>
				Do 24 hodin vás budeme kontaktovat.
			</Typography>
			<Typography variant='body1' style={{ textAlign: 'right' }}>
				— Váš tým Youklidu.
			</Typography>
		</ContentWrapper>
	);
}

export default RecruitmentThankYouPage;